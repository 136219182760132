@import "../../../../assets/style/mixins.less";

.container {
  height: calc(100vh - @header-height);
  background-color: #fff;
  overflow: auto;
  overflow-x: hidden;
  position: sticky;
  top: @header-height;
  box-shadow: 0 0 5px #00000025;
  z-index: 0;

  /* width */
  &::-webkit-scrollbar {
    width: 6px !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(186, 192, 198);
    border-radius: 10px;
  }

  .input-search {
    padding: 11px;
  }

  .padding-list {
    padding: 0 4px;
  }

  .wrapper {
    border-bottom: 1px solid #ededed;
    width: 100%;

    &:last-child {
      border-bottom: none;
    }

    .search-icon {
      background-color: #f5f7fb;
      font-size: 20px;
      border: none;

      * {
        color: @main-color;
      }
    }

    .tabs {
      margin-bottom: 15px;

      * {
        font-size: 12px;
        font-weight: 600 !important;
      }
    }

    .patient-card {
      border: 1px solid #e0e0e0;
      border-radius: 0 5px 5px 0;
      cursor: pointer;
      margin-bottom: 10px;
      border-radius: 10px;
      // padding: 5px;

      * {
        color: @navy1;
      }

      &.active {
        background-color: @navy1;

        .inner {
          border-bottom-color: #4c5ab8;
        }

        .head * {
          color: #fff;
        }

        .body * {
          color: #d5d7e6;
        }

        .stt {
          background-color: @main-color;
          border-radius: 5px;
        }
      }

      .inner {
        padding: 5px 10px;
        border-bottom: 1px dashed #c4c3c3;

        &:last-child {
          border-bottom: none;
        }

        &.head * {
          font-weight: bold !important;
        }

        &.body * {
          font-size: 12px;
        }
      }

      .name {
        width: 100%;
        .box-name {
          display: flex;
          align-items: center;
          flex-direction: row;
          .dot-status {
            width: 6px;
            height: 6px;
            border-radius: 10px;
            //margin-right: 10px;
            background-color: #2cb2a5;
            margin: 0 5px;

            &.red {
              background-color: @red3;
            }
            &.gray {
              background-color: #2cb2a5;
            }
          }
        }
      }

      .stt {
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-inline: 5px;
        min-width: 25px;
      }
    }
  }
}

.filter {
  border: 1px solid @main-color;
  border-radius: 5px;
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  color: @main-color;
  width: 100%;
  height: 100%;
  cursor: default;
}

.popup-wrapper {
  padding: 0px;
}
.popup {
  width: 120px;

  .popup-item {
    padding: 5px 10px;
    border-radius: 5px;
    color: #2c3782;
    font-size: 14px;
    font-weight: 600;
    &:hover {
      color: #fff;
      background-color: #6576ff;
    }
  }
}

.modal {
  padding: 20px;
  .title-action {
    color: #6576ff;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
  }
  .button-create {
    width: 150px;
    margin-top: 20px;
  }
}

.fw-600 {
  font-weight: 600 !important;
}
.fw-500 {
  font-weight: 500 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.today__btn--wrap {
  color: @blue2;
  padding: 4px 0;
  font-size: 13px;

  border: 1px solid @blue2;
  border-radius: 8px;

  text-align: center;
  cursor: pointer;
}

.sider-line {
  margin: 4px 0;
}

.pop-up-time {
  border-radius: 30px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  :global(.ant-popover-content) {
    padding: 0;
  }
  :global(.ant-popover-arrow) {
    display: none;
  }
  :global(.ant-popover-inner-content) {
    margin-top: -12px;
    padding: 0;
  }
}
.pop-item {
  width: 184px;
  padding: 10px;
  font-size: 13px;
  font-weight: 600 !important;
  cursor: pointer;
  color: @navy1;
  &:hover {
    background-color: @main-color;
    color: #fff;
    &:nth-last-child(1) {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.date-pick {
  width: 100%;
  height: 36px;
  input {
    font-size: 12px !important;
  }
}

.filter-btn {
  padding: 4px 6px;
  min-width: 98px;
  font-size: 12px !important;
  font-weight: 700 !important;
  text-align: center;
  border-radius: 5px;
  border: none;
  background-color: #f5f7fb;
  color: @navy1;
  // width: 136px !important;

  cursor: pointer;
}

.filter-btn-active {
  background-color: @blue2;
  color: #fff;
}
.mr-center {
  line-height: 30px;
}

.popup-select {
  z-index: 2;
}
