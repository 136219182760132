@import "../../../assets/style/mixins.less";

.ket-luan-wrap {
  min-height: 100px;
  overflow: auto;
}

.ket-luan-content {
  height: 100%;
  overflow: auto;
}

.form-draw{
  border: 1px solid rgb(230, 230, 230);
  border-radius: 10px;
  overflow: hidden;
}

.form-header {
  padding: 10px 20px;
  border-bottom: 1px solid rgb(235, 235, 235);
}

.form-content {
  padding: 10px 20px;
}

.boldTextArea {
  font-weight: bold !important;
}

.selectEditor {
  width: 250px;
  :global(.ant-select-selector) {
    // background-color: #fff !important;
  }
}

// .file-dinh-kem{
//   z-index: 10000000;
//   display: flex;
//   padding: 6px 8px;
//   align-items: center;
//   border-radius: 5px;
//   border: 1px solid #6576FF;
//   color: #6576FF;
//   margin-left: 30px;
//   gap: 10;
//   cursor: pointer;
//   svg {
//     transform: rotate(45deg);
//     margin-right: 4px;
//     path {
//       stroke: #6576FF
//     }
//   }
// }

.file-dinh-kem{
  position: absolute;
  top: -24px;
  right: 0;
  z-index: 10000000;
  display: flex;
  height: 24px;
  padding: 0px 10px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #6576FF;
  color: #ffffff;
  background-color: #6576FF;
  gap: 10;
  cursor: pointer;
  font-size: 14px !important;
  svg {
    margin-right: 4px;
  }
}

.button-svg{
  display: flex;
  padding: 5px 15px;
  background-color: #6576ff;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  svg {
    transform: rotate(45deg);
    margin-right: 4px;
  }
}


.item-file{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(245, 247, 251);
  border-radius: 10px;
  padding: 14px !important;
}

.item-file-2{
  flex-grow: 1;
    flex-shrink: 0;
}
.item-file-3{
  cursor: pointer;
    background-color: transparent;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    z-index: 70;
}


.form-container {
  height: 100%;
  overflow: auto !important;

  :global(.ant-drawer-body) {
    padding-right: 5px;
  }
  :global(.ant-drawer-header) {
    background-color: #f5f7fb;
  }

  :global(.ant-drawer-close) {
    margin: 0 0 0 21px;
    padding: 0;
  }

  :global(.ant-form-item) {
    margin: 0 !important;
  }
  .header-title {
    background-color: #f5f7fb;
    // height: 55px;
    align-items: center;
    padding: 12px 26px;
    padding-left: 0;

    .title-drawer {
      display: flex;
      align-items: center;
      gap: 10px;
      h2 {
        font-size: 22px;
        font-weight: 700 !important;
      }
    }
  }
  .form__wrap--chitiet {
    padding: 2px 6px;
    input {
      font-size: 13px;
    }
    :global(.ant-col) {
      :global(.ant-form-item) {
        margin-bottom: 0;
      }
      :global(.ant-form-item-label) {
        margin: 0;
        padding-bottom: 0;
        label {
          font-size: 13px !important;
        }
      }
    }
  }
  .form-wrap {
    font-size: 14px !important;
    overflow: auto;
    span {
      font-size: 14px;
      color:#2C3782;
    }
    input {
      font-size: 13px;
      border-color:#E5E9FB;
    }
    :global(.ant-col) {
      :global(.ant-form-item) {
        margin-bottom: 0;
      }
      :global(.ant-form-item-label) {
        margin: 0;
        padding-bottom: 0;
        label {
          font-size: 14px !important;
        }
      }
    }
  }

  :global(.ant-drawer-body) {
    display: flex;
    flex-direction: column;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .container {
    flex: 1;
    height: calc(100% - 70px);
    
  }
  min-height: 100%;
  div {
    font-size: 14px;
  }
  span {
    font-size: 14px;
  }

  .form__header {
    box-sizing: border-box;
    // height: 70px;
    padding: 6px 16px;

    border-bottom: 1px solid #ededed;
    // :global(.ant-upload-list){
    //   display: none;
    // }
    :global(.ant-form-item-label) {
      padding-bottom: 2px;
    }
    label {
      font-size: 14px !important;
    }
  }
}

.form__top--action {
  display: flex;
  flex-direction: row;
  height: 32px;
  span {
    font-size: 14px !important;
  }
  font-size: 14px !important;

  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background: #fff;
  align-items: center;

  .patient-title {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 12px;
    border-right: 1px solid #d7d7d7;

    &:last-child {
      border-right: none;
    }
  }
}
.btn__top--action {
  // margin-top: 20px;
  // width: 100%;
  // max-width: 100px;
  max-height: 32px;
  // padding: 0;
  :global(.ant-upload.ant-upload-select) {
    width: 100%;
  }
}
.btn__custom--upload {
  display: flex;
  flex-wrap: nowrap;
  min-width: 116px;
  max-width: fit-content;
  height: 32px;

  // margin-top: 20px;
  background-color: #6576ff;
  color: #fff;

  border-radius: 6px;
  cursor: pointer;
  padding: 4px 15px;
  &:hover {
    background-color: #8392fe !important;
  }
}

.wrap-btn-upload {
  span {
    width:unset !important;
  }
}

.btn__upload--disabled {
  color: rgba(0, 0, 0, 0.25);
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
  &:hover {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5 !important;
    text-shadow: none;
    box-shadow: none;
  }
}

.form__header--thongtin {
  padding: 8px;
  border-bottom: 1px solid #ededed;
  background: #F5F7FB;

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  input,
  :global(.ant-select-selector) {
    background-color: #ffffff !important;
  }
}

.form__sider--left {
  // border-right: 1px solid #ededed;
  .sider-items {
    padding: 8px 12px;
    position: relative;
    .input {
      padding-left: 24px;
    }
    .search-icon {
      position: absolute;
      top: 40%;
      left: 10%;
    }
    margin-bottom: 10px;
  }
}
.form__sider--right {
  width: 100%;
  // border-left: 1px solid #ededed;
  z-index: 2;
  padding: 8px 5px 5px 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.container-content {
  // overflow: hidden;
  :global(.ant-collapse-header) {
    height: 32px;
    padding: 5px 12px !important;
  }
  :global(.ant-collapse-item) {
    background-color: #f5f7fb !important;
  }

  :global(.ant-collapse) {
    margin-top: 10px !important;
  }

  overflow: auto;
  .text-editor {
    :global(.tox-sidebar-wrap) {
      min-height: 200px;
      position: relative;
      // overflow: auto;
      // max-height: calc(100vh - (@header-height * 3 + 265px));
      padding-top: 20px;

      // &::after {
      //   content: "Mô tả";
      //   position: absolute;
      //   top: 10px;
      //   left: 15px;
      //   font-size: 15px;
      //   font-weight: 700;
      //   color: @navy1;
      //   background-color: #f5f7fb;
      //   width: 100%;
      //   padding-bottom: 5px;
      //   // margin-top: 10px;
      //   // margin-left: 10px;
      // }
    }

    :global(.tox-sidebar-wrap) {
      min-height: 200px;
      position: relative;
      // overflow: auto;
      // max-height: calc(100vh - (@header-height * 3 + 265px));
      padding-top: 20px;

      // &::after {
      //   content: "Mô tả";
      //   position: absolute;
      //   top: 10px;
      //   left: 15px;
      //   font-size: 15px;
      //   font-weight: 700;
      //   color: @navy1;
      //   background-color: #f5f7fb;
      //   width: 100%;
      //   padding-bottom: 5px;
      //   // margin-top: 10px;
      //   // margin-left: 10px;
      // }
    }
  }

  .text-editor-kl {
    width:100%;
    height: calc(100% - 26px);
    :global(.tox-sidebar-wrap) {
      min-height: 100px;
      position: relative;
      padding-top: 20px;
    }

    :global(.tox-sidebar-wrap) {
      min-height: 100px;
      position: relative;
      padding-top: 20px;
    }
  }
  .editor-disabled {
    :global(.tox .tox-menubar) {
      display: none;
      // opacity: 0;

      .tox-mbtn__select-label, button{
        cursor: default !important;
      }
    }
    :global(.tox .tox-toolbar-overlord) {
      display: none;
    }
  }

  // &::-webkit-scrollbar {
  //   width: 6px !important;
  // }

  // /* Handle */
  // &::-webkit-scrollbar-thumb {
  //   background: #dadce0;
  //   border-radius: 10px;
  // }
}
.list__dv--wrap {
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 0 !important;
  :hover {
    background-color: #6576ff;
    color: #fff;
  }
  &::-webkit-scrollbar {
    width: 6px !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #dadce0;
    border-radius: 10px;
  }
}


.list__dv--wrap-2 {
  overflow-x: hidden;
  // overflow-y: scroll;
  margin: 0 !important;
  padding: 0px 12px;
  :hover {
    background-color: #6576ff;
    color: #fff;
  }
  &::-webkit-scrollbar {
    width: 6px !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #dadce0;
    border-radius: 10px;
  }
}

.service__row {
  color: @navy1;
  font-weight: 600 !important;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 4px;
}
.service__row--active {
  background-color: @navy1;
  color: #fff;
}

.txt-overflow {
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.sider__right--background {
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  border-left: 1px solid #ededed;
  padding: 8px 5px 5px 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sub-header {
  background-color: #fff !important;
  .sub-header-title {
    color: @navy1;
    font-size: 16px;
    font-weight: 700;
  }
}

.iframe-mota {

  position: relative;
  height: 81%;
  max-height: 81%;
  overflow: auto;
  padding-inline: 20px;
}

.no-click {
  // pointer-events: none;
}

.suffix-input {
  background-color: #fff;
  input {
    background-color: #fff;
  }
}

.desc-container {
  overflow: auto;
  .style-scroll-bar;
}