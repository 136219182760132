.container {
  background-color: #fff;
  box-shadow: 0 0 5px #00000025;
  border-radius: 5px;
  width: 60vw;

  .title {
    padding: 8px 15px;
    margin-bottom: 0;
  }

  .table-ctn {
    border-top: 1px solid #00000010;
  }

}

.template-container {
  background-color: #fff;
  box-shadow: 0 0 5px #00000025;
  border-radius: 5px;
  width: 600px;

  :global(.ant-table-header .ant-table-thead .ant-table-cell) {
      padding-bottom: 0px !important;
  }
  .title {
    padding: 13px 15px;
    margin-bottom: 0;
  }

  .table-ctn {
    border-top: 1px solid #00000010;
  }

}
.delele-container {
  flex-direction: column;
  justify-content: center;
  .delete-icon {
    fill: #999999;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  svg{
    font-size: 16px;
  }
  &:hover {
    fill: #f34946;
  }
}
}


.replace-button{
  background-color: #2CB2A5;
  border-color: #2CB2A5;
  margin-left: 5px;
}
