@import "../../../assets/style/mixins.less";

.sider {
  min-width: 234px !important;
  background-color: #ffffff;
}

.sider_time {
  padding: 17px 17px 0px;
  span {
    &:first-child {
      display: block;
      margin-bottom: 10px;
    }
  }
}

.divider {
  margin: 12px 0;
}

.divider_search{
  margin: 0!important;
}

.sider_search{
  margin: 9px 15px !important;
}

.sider_status {
  display: flex;
  flex-direction: column;
  padding: 0px 17px;
}

.divider {
  margin: 16px 0;
  margin-right: -17px;
  margin-left: -17px;
}

.button {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 0 17px;
  button {
    flex: 1;
  }
}

.topbar {
  background-color: #ffffff !important;
}

.table {
  padding: 16px 16px 0;
}

.button_action {
  display: flex;
  align-items: center;
}

.packet_service_info {
  margin: 12px 19px;
}

.form {
  border-radius: 10px;
  background-color: #fff;
  padding: 12px;
  :global(.ant-input-number-group-addon) {
    background-color: #f4f7ff;
  }
}

.datepicker {
  border-radius: 5px;
  background-color: #f4f7ff;
  width: 100%;
}

.extra {
  display: flex;
  align-items: center;
  gap: 12px;
}

.select {
  width: 212px;
  :global(.ant-select-selector) {
    background-color: #fff !important;
  }
}

.button_copy {
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    filter: brightness(0) invert(1);
  }
}

.sider-form {
  background-color: #fff;
  padding: 12px;
  border-radius: 10px;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.05);
  margin-right: 12px;
  min-width: 250px !important;
}

.wrapper {
  margin-bottom: 6px;
}

.segmented {
  background-color: #f5f7fb;

  :global(.ant-segmented-item-label) {
    color: @navy1;
    padding-block: 2px;
    white-space: pre-line;
    line-height: 18px;
  }

  :global(.ant-segmented-item-selected),
  :global(.ant-segmented-thumb-motion) {
    background-color: @main-color;

    :global(.ant-segmented-item-label) {
      color: #fff;
    }
  }

  :global(.ant-segmented-thumb-motion-appear-active) {
    transition-duration: 0.01s;
  }
}

.options {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px !important;
    height: 9px !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
  }
}

.tableS {
  height: unset !important;
  :global(.ant-table-row-level-0) {
    :global(.ant-table-cell-with-append) {
      svg {
        margin-right: 10px;
      }
    }
  }
  :global(.ant-table-content) {
    &::-webkit-scrollbar {
      width: 6px !important;
      height: 9px !important;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 10px;
    }
  }
  :global(.ant-input-number) {
    width: 100%;
  }
  :global(.ant-input-number-group-addon) {
    background-color: #f5f7fb;
  }
}

.footer-table {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 7px 7px 12px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  color: #2c3782;
  .text-color {
    color: #6576ff;
    font-size: 14px;
    font-weight: 700 !important;
    line-height: 32px;
  }
  :global(.ant-input-number) {
    width: 100%;
  }
}

.text-color {
  color: #6576ff;
  font-size: 14px;
  font-weight: 700;
}

.packet_service_footer {
  // margin-top: 14px;
  // padding-bottom: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: sticky;
  bottom: 0;
  background: #ffffff;
  margin-bottom: -14px;
  padding: 14px 0;
  z-index: 69;
}

.button_footer {
  display: flex;
  gap: 18px;
}

.popup-select {
  :global(.ant-select-tree-switcher) {
    display: none;
    span {
      display: none;
    }
  }
  :global(.ant-select-tree-indent) {
    display: none;
  }
  :global(.ant-select-tree-list) {
    padding: 0px 8px;
  }
}

.input-number {
  :global(.ant-input-number-handler-wrap) {
    display: none;
  }
}

.exemption-form-item {
  border-radius: 10px;
  background-color: #ffffff;
  padding: 24px !important;

  :global(.ant-form-item) {
    margin-bottom: 0;
  }
}

.item-exemption {
  display: flex;
  align-items: center;
  gap: 6;
  background-color: #F5F7FB;
  padding: 6px;
  border-radius: 5px;
  position: relative;
}

.item-icon-trash {
  position: absolute;
  right: -24px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.btn-add-user-group {
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
  span{
    margin-left: 8px;
  }
}


