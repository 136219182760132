@import "../../../../assets/style/mixins.less";

.container {
  // padding: 15px;

  h3 {
    margin-bottom: 5px;
  }

  .dot-item {
    position: relative;
    padding-left: 15px;
    margin-block: 5px;
    color: @navy1;

    * {
      color: @navy1;
    }

    &::before {
      content: "";
      width: 5px;
      height: 5px;
      background-color: @main-color;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }

    span {
      font-weight: 600 !important;
    }
  }

  .box {
    color: @navy1;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #c9cdea;
    background-color: #fff;
    margin-left: 10px;
    // margin-bottom: 15px;
  }
  
  .wrap-content {
    height: calc(100vh - 150px);
    padding: 10px;
    overflow: auto;
    // background-color: @white;
    .info-don-thuoc{
      background-color: @white;
      border-radius: 10px;
      :global(.ant-tooltip-inner){
        background-color: #fff !important;;
        color: #000 !important;
        min-width: 60vw !important;
      }

      .info-item-up-left{
        padding: 10px;
        border-bottom: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        color:@navy1;
      }
      .info-item-up-right{
        padding: 10px;
        border-bottom: 1px solid #e0e0e0;
        color:@navy1;
      }
      .info-item{
        padding: 10px;
        color:@navy1;
        // overflow-wrap: break-word;
        max-height: 65px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
          
      }
    }
  }
  .wrap-content-full-screen {
    height: 100%;
  }

  .footer {
    background-color: @white;
    // border-top: 1px solid #e0e0e0;
    padding: 10px 10px 10px 0;
  }
}

.sum-price-title {
  color: @navy1;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 600 !important;
}

.sum-price-value {
  color: @green1;
  font-size: 14px;
  font-weight: 600 !important;
}

.elipsis-column {
  // width: '100%';
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}