.input-ctn {
  * {
    cursor: pointer;
    color: #cecece !important;
  }

  :global(.ant-input) {
    color: #2c3782 !important;
    font-size: 13px !important;
  }
  .text-price {
    :global(.ant-input) {
      color: #2cb2a5 !important;
      font-size: 13px !important;
    }
  }

  svg {
    color: #cecece !important;
  }
}

.popup-thx{
    z-index: 999 !important;
}

.popup-ctn {
  background-color: #fff;
  box-shadow: 0 0 10px #00000025;
  border-radius: 5px;
  overflow: hidden;
  .popup-thx-table {
    th {
      background-color: #f9f9f9;
    }
  }
}

.row-title {
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 05px;
  margin-bottom: 20px;
  span {
    color: #2c3782;
    // font-size: 12px;
    font-weight: 600;
  }
}

.row-table {
  background-color: #fff;
  padding: 10px 15px;
  border-bottom: 1px solid #e2e2e2;

  span {
    color: #2c3782;
  }
  .icon {
    display: flex;
    justify-content: center;
    svg {
      filter: invert(24%) sepia(99%) saturate(7500%) hue-rotate(360deg)
        brightness(111%) contrast(118%);
      width: 20px;
      height: 20px;
      //margin-left: 10px;
    }
  }
}

.thong__tin--benh-nhan {
  border-radius: 12px;
  max-width: 560px;
  @media only screen and (max-width:1100px) {

  max-width: 420px;

  }
}