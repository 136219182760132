.edit-container {
    :global(.tui-image-editor-header-buttons) {
        display: none !important;
    }

    :global(.tui-image-editor-header-logo) {
        display: none !important;
    }

    :global(.ant-upload-list-item-info) {
        display: none !important;
    }

    :global(.ant-upload-list-item-list-type-text) {
        display: none;
    }
}