@import "../../../assets/style/mixins.less";

.container {
  padding: 15px 24px 22px 13px;
  height: calc(100vh - @header-height);
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.wrapper {
  height: 100%;
}

.my-question {
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  height: calc(100vh - @header-height - 37px);
}

.submit-question {
  background-color: #ffffff;
  padding: 24px 17px 26px 17px;
  margin-bottom: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  p {
    color: #2c3782;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
  }
  span {
    font-weight: 600;
    font-size: 13px;
    color: #8f99d3;
  }
}

.mail-logo {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f7fb;
  border-radius: 50%;
  margin-right: 15px;
}

.recent-question {
  background-color: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  flex: 1;
  overflow: auto;

  .block {
    padding: 11px 10px 12px 14px;
    border-bottom: 1px dashed #dedede;

    &:last-child {
      border-bottom: none;
    }

    p {
      font-weight: 600;
      font-size: 14px;
      color: #2c3782;
    }

    .time {
      display: flex;
      align-items: center;
      margin-bottom: 9px;

      span {
        font-weight: 600;
        font-size: 12px;
        color: #2c3782;
        opacity: 0.5;
      }
      svg {
        margin-right: 5px;
      }
    }
  }
}

.faq-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #2c3782;
  padding: 18px;
  padding-bottom: 13px;
}

.line {
  border-bottom: 1px solid #dedede;
}

.common-question {
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
  border-radius: 8px;

  :global(.ant-collapse-item-active) {
    border-top: none !important;
  }
  :global(.ant-collapse-item-active .ant-collapse-header) {
    background: #f0f2f6 !important;
  }
  :global(.ant-collapse-content-box) {
    padding: 8px 25px 21px 22px;
    font-weight: 500;
    font-size: 13px;
    color: #2c3782;
  }

  .collapse {
    overflow: hidden;
    background: #ffffff;
    border-top: 1px dashed #dedede;
    border-bottom: none;

    &:first-child {
      border-top: 1px solid #dedede;
    }
  }
}
