@import "../../../assets/style/mixins.less";

.container {
  display: flex;
  flex-direction: column;

  // :global(td.ant-table-cell-row-hover) {
  //   background: @navy1 !important;
  // }

  .table-service {
    //max-height: 700px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .search {
    background-color: #fff;
    border: 1px solid #eef4ff;
    border-radius: 50px;
    width: 250px;

    input {
      background-color: #fff;
      border-radius: 0px !important;
    }

    .icon {
      color: @main-color;
      margin-right: 5px;
      font-size: 20px;
    }

    &.search-responsive {
      @media screen and (max-width: 1400px) {
        display: none;
      }
    }
  }

  .info-payment {
    // margin-top: 20px;
    .box-payment {
      // background-color: #fff;
      border-radius: 10px;
      padding: 20px 0px;

      .col-info {
        padding: 0px 20px;
        .warning-text {
          color: @red1;
        }

        p {
          font-size: 12px;
          font-weight: 700;
          margin-bottom: 10px;
          font-weight: 600 !important;
          color: #2c3782;
        }
        .title-money {
          color: #2c3782;
          font-size: 12px;
          line-height: 17px;
          font-weight: 500;
        }

        // .input {
        //   width: 100%;
        //   //margin-right: 30px !important;
        //   height: 40px;
        // }
        .money-special {
          color: #2cb2a5;
          font-size: 14px !important;
        }
      }
    }
    .select-custom {
      background-color: #fff !important;
      width: 100%;
      height: 100%;
    }

    :global(.ant-select-selector) {
      background-color: #fff !important;
      border-color: #e9e9e9;
    }
  }
}
.row {
  margin: 8px 0;
}
.money {
  color: #2c3782;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
}
.green-tag {
  font-size: 14px;
  padding: 3px 10px;
  text-align: center;
  color: @green1 !important;
  background-color: @green2 !important;
  border: 1px solid @green1 !important;
  font-weight: 600 !important;
  margin-top: -5px;
  margin-bottom: -5px;
  border-radius: 5px;
  width: 100%;
}

.red-tag {
  .green-tag;
  color: @red1 !important;
  background-color: @red2 !important;
  border-color: @red1 !important;
}

.orange-tag {
  .green-tag;
  color: @orange1 !important;
  background-color: @orange2 !important;
  border-color: @orange1 !important;
}
.row-button {
  background-color: #fff;
  padding: 16px;
}
.button-status {
  font-size: 10px !important;
  width: 157px !important;
  height: 30px;
  text-transform: uppercase;
  @media screen and (max-width: 1300px) {
    min-width: 120px !important;
  }
  background-color: #f5f7fb;
  border: none;
  height: 30px;
  border-radius: 5px;
  // box-shadow: 0 0 10px #00000020;
  p {
    color: #999999;
    font-weight: 600 !important;
    font-size: 13px;
  }
  span {
    color: #999999;
    font-weight: 600 !important;
    font-size: 10px;
  }
  &.button-status-active {
    background-color: #2c3782;
    p {
      color: #fff;
    }
    span {
      color: #fff;
    }
  }
}

.money-special {
  color: #2cb2a5;
  font-size: 14px;
}
.title-money {
  color: #2c3782;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}
.refund {
  // margin-top: 10px;
  .select-custom-refund {
    background-color: #fff !important;
    width: 100%;
    border: 0.8px solid #e2e2e2;
    border-radius: 5px;
  }
  :global(.ant-select-selector) {
    background-color: #fff !important;
    border-color: #e9e9e9;
  }
}

.icon-delete {
  &>button:disabled {
    svg {
      -webkit-filter: opacity(0.6);
      filter: opacity(0.6);
    }
  }
  &:hover {
    svg {
      -webkit-filter: invert(24%) sepia(99%) saturate(7500%) hue-rotate(360deg) brightness(111%) contrast(118%);
      filter: invert(24%) sepia(99%) saturate(7500%) hue-rotate(360deg) brightness(111%) contrast(118%);
    }
  }
}

.report-btn {
  background-color: @main-color;

  svg {
    margin-top: 3px;
    margin-bottom: -3px;
    margin-right: 10px;
  }
}
.title {
  color: @navy1;
  font-size: 16px;
  span {
    font-weight: 700 !important;
    font-size: 14px;
    line-height: normal;
  }
}
.money-duration {
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
}
.input {
  background-color: #f5f7fb;
  input {
    background-color: #f5f7fb;
  }
}
.custom-label {
  margin-top: 110px;
}

@media screen and (max-width: 1040px) {
  .title-payment {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.payment-select {
  flex-wrap: unset;
}
.title-payment {
  color: @navy1;
  font-size: 13px;
  margin-top: 8px;
}

.custom_title-table {
  background-color: #fff;
  padding: 8px 4px;
  border-radius: 4px;
  margin-bottom: 14px;
  box-shadow: 1px 1px 0.5px 0.5px #ececec;
}
.row--dich_vu-tt {
  background-color: #fff;
  padding: 8px 4px;
  border-bottom: 1px solid #e4e4e4;
  color: @navy1;
}
.icon {
  cursor: pointer;
}

.txt-highlight {
  font-size: 14px !important;
  font-weight: 700 !important;
}

.content-flex {
  display: flex;
  flex-direction: row;
}
.payment-sider {
  // @media screen and (max-width: 1300px) {
  //   width: 250px !important;
  // }
  label {
    font-size: 12px;
  }

  background-color: rgba(255, 255, 255, 0.897);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
  }
  :global(.ant-form-item) {
    margin-bottom: 8px;
  }
}

.hidden-content {
  tbody{
    &::before {
      display: none !important;
    }
  }
}

.table-custom {
  // tr[data-row-key = 'THONG_TIN_GOI'] {
  //   td:first-child {
  //     display: none;
  //   }
  // }
  :global(.ant-empty) {
    height: 100%;
    min-height: calc(100vh - 400px) !important;
    padding: 10%;
  }
  :global(.ant-empty-normal .ant-empty-image) {
    height: 100%;
  }
  // :global(.ant-table-row-level-0) {
  //   box-shadow: none;
  //   font-size: 14px;
  //   font-weight: 700;
  //   text-transform: uppercase;
  //   // td:first-child {
  //   //   display: none;
  //   // }
  //   :global(.ant-table-cell-with-append) {
  //     background-color: #F5F7FB;
  //     border: none;
     
  //     &:hover {
  //       background-color: #F5F7FB;
  //     }
  //   }
  // }
  :global(.ant-input-number-group-addon) {
    background-color: #f5f7fb;
  }
}
.title {
  color: @navy1 !important;
}
.sider-item {
  padding: 4px 12px;
}

.row-table-split {
  cursor: pointer;
  :global(.ant-table-cell) {
    // background-color: #f5f7fb;
  }
}
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
  // padding-right: 16px;
}

.payment-container {
  padding: 12px;
  border-radius: 10px;
  background: #F5F7FB;
  margin: 7px 2px 0;
  position: relative;
  input {
    background-color: #fff;
  }
  :global(.ant-select-selector) {
    background-color: #fff !important;
  }
  .delete-btn {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 10;
    &:hover {
      opacity: 0.8;
    }
  }
  :global(.ant-input-number) {
    width: 100%;
    z-index: 1;
  }
  :global(.ant-input-number-group) {
    :global(.ant-input-number-input) {
      border-radius: 5px 0 0 5px;
    }
  }
  :global(.ant-input-number-group-addon) {
    background-color: #fff;
    padding: 0;
    button {
      width: 30px;
      height: 30px;
    }
  }
}

.btn-add {
  cursor: pointer;
  align-items: center;
  margin: 7px 7px 0;
  &:hover {
    opacity: 0.8;
  }
}
.btn-cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.8;
  }
}

.modal-content {
  :global(.ant-modal-content) {
    padding: 0 20px;
  }

  :global(.ant-divider) {
    display: none;
  }
}

.debt-list-container {
  padding: 12px;
  border-radius: 15px;
  background-color: #F5F7FB;
  margin-bottom: 24px;
}

.title-modal {
  color: #2C3782;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  &>button:disabled{
    opacity: 0.4;
  }
}

.cancelRes {
  margin: 0 20px;
  padding: 15px;
  background: rgba(245, 247, 251, 1);
  border-radius: 15px;
  color: rgba(44, 55, 130, 1);
  p {
    text-align: center;
    line-height: 17.5px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700 !important;
  }
  label {
    font-weight: 600 !important;
    font-size: 14px;
  }
  :global(.ant-form-item) {
    margin: 0;
  }
  input {
    background: white !important;
  }
  // :global(.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before) {
  //   display: none;
  // }
  // :global(.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after) {
  //   display: inline-block;
  //   margin-inline-start: 4px;
  //   margin-bottom: 8px;
  //   color: #ff4d4f;
  //   font-size: 10px;
  //   font-family: SimSun,sans-serif;
  //   line-height: 1;
  //   content: "*";
  //   visibility:unset !important;
  // }
}

.hideFirst {
  :global(.ant-table-row-level-0) {
    box-shadow: none;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    td:first-child {
      display: none;
    }
    :global(.ant-table-cell-with-append) {
      background-color: #F5F7FB;
      border: none;
     
      &:hover {
        background-color: #F5F7FB;
      }
    }
  }
  :global(.ant-table-row-level-1) {
    :global(.ant-checkbox-wrapper-disabled) {
      display: none !important;
    }
  }
}

.hideChild {
  :global(.ant-table-row-level-0) {
    box-shadow: none;
    font-size: 14px;
    font-weight: 700;
    :global(.ant-table-cell-with-append),td:first-child  {
      background-color: #F5F7FB !important;
      border: none;
      padding-right: 0 !important;
    }
    :global(.ant-checkbox-wrapper-disabled) {
      display: none;
    }
    
  }
  :global(.ant-table-row-level-1) {
    :global(.ant-checkbox-wrapper) {
      display: none !important;
    }
  }
  .summary {
    // height: 36px;
    background: #fff;
    align-items: center;
    position: relative;
    padding: 4px 0;
    // border-top-right-radius: 10px;
    // border-bottom-right-radius: 10px;
    :global(.ant-col) {
      color: rgba(101, 118, 255, 1);
    }
    .disInput {
      vertical-align: middle;
      :global(.ant-input-number) {
        width: 108px;
        :global(.ant-input-number-input) {
          width: unset;
        }
      }
    }
  }
  .wall {
    position: absolute;
    width: 100%;
    height: 100%;
    left: -69px;
    background: #fff;
    // border-bottom-left-radius: 10px;
    // border-top-left-radius: 10px;
  }
}

.bundleInfo {
  td {
    padding: 10px 8px 8px !important;
  }
  :global(.ant-table-cell) {
    background-color: #f5f7fb !important;
  }
  span {
    color: rgba(44, 55, 130, 1);
    font-weight: 700 !important;
    font-size: 14px;
  }
  :global(.ant-checkbox) {
    margin-right: 10px !important;
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  :global(.ant-btn-link) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    svg {
      width: 20px;
      height: 20px;
    }
    .print {
      color: #999999;
      &:hover {
        color: fuchsia;
      }
    }
    .delete:hover {
      path {
        fill: red;
      }
    }
    .editIcon {
      path {
        fill: #999999;
      }
      &:hover {
        path {
          fill: #2cb2a5;
        }
      }
    }
  }
  :global(.ant-btn-link[disabled]) {
    opacity: 0.5;
  }
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.25);
}