.container {
    :global(.ant-modal-body){
        padding: 0 24px;
    }
    :global(.ant-modal-footer){
        padding: 20px 24px;
    }
    .image-list {
        width: 100%;
        margin: 0!important;
        .image-item{
            position: relative;
            display: flex;
            :global(.ant-image) {
                display: flex;
                aspect-ratio: 1;
                img{
                    object-fit: cover;
                }
            }

        }
        .image-checkbox{
            position: absolute;
            top: 6px;
            left: 10px;
            z-index: 5;
        }
    }
    .empty{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}