@import "../../../assets/style/mixins.less";

.container {
  .total-amount {
    margin-top: 10px;
    * {
      font-size: 14px;
    }
  }
  :global(.ant-select), :global(.ant-input-number) {
    width: 100%;
  }
  :global(.ant-input-number-group-addon) {
    background-color: #f5f7fb;
  }
  .important {
    font-weight: 700 !important;
    span {
      font-size: 14px;
      color: rgba(243, 73, 70, 1);
      font-weight: 700 !important;
    }
  }
  .table {
    :global(.ant-table-cell-fix-left) {
      left: 0 !important;
    }
  }
}

.nestedCollapse {
  background-color: #F4F7FF !important;
  margin-top: -10px;
  gap: 0 !important;
  :global(.ant-collapse-header-text) {
    font-size: 15px !important;
  }
  :global(.ant-collapse-content-box) {
    padding: 0;
    background-color: #F4F7FF;
    margin-bottom: 10px;
  }
  h4 {
    margin: 10px 0 5px;
    text-transform: uppercase;
  }
  :global(.ant-collapse-item) {
    margin-bottom: 0 !important;
  }
}

.drawer {
  :global(.ant-drawer-header) {
    background: #f5f7fb;
    padding-right: 20px;
  }
  .spaceDiv {
    width: 100%;
    justify-content: space-between;
  }
  :global(.ant-drawer-body) {
    padding: 12px 15px 0 15px;
  }
  :global(.ant-collapse) {
    background-color: transparent;
    :global(.ant-collapse-header) {
      align-items: center;
      :global(.ant-collapse-arrow) {
        font-size: 14px;
        margin: 4px 7px 0 5px;
      }
      :global(.ant-collapse-header-text) {
        font-size: 18px;
        font-style: normal;
        font-weight: 700 !important;
        text-transform: uppercase;
      }
    }
  }
  :global(.ant-collapse > .ant-collapse-item) {
    border-radius: 10px;
    background: #F4F7FF;
    border-bottom: none;
    margin-bottom: 15px;
    .wrapper {
      border-radius: 10px;
      background: #FFF;
      padding: 12px;
    }
    h4 {
      margin: 12px 0 6px;
      text-transform: uppercase;
    }
  }
  :global(.ant-drawer-footer) {
    padding: 16px 24px;
    :global(.ant-btn) {
      min-width: 124px;
    }
  }
  :global(.ant-input-number) {
    width: 100%;
  }
  :global(.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    background: #f5f7fb;
    cursor: default;
    color: #2c3782 !important;
    &:hover {
      border-color: #8fa0ff;
      border-right-width: 1px;
    }
  }
  :global(.ant-form-item) {
    margin-bottom: 8px;
  }
  :global(.ant-picker) {
    width: 100%;
    background: #f5f7fb;
    input {
      color: #2c3782 !important;
    }
  }
}

.extra {
  :global(.ant-select) {
    width: 212px !important;
  }
  :global(.ant-select-selector),
  :global(.ant-picker) {
    background-color: #fff !important;
  }
  button {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .copy {
    filter: brightness(0) invert(1);
  }
  .delete {
    svg {
      fill: #f34946;
      width: 20px;
    }
    &:hover {
      svg {
        fill: #ff7875;
      }
    }
    &:disabled {
      svg {
        fill: rgba(0, 0, 0, 0.25) !important;
      }
    }
  }
}

.sider-form {
  background-color: #fff;
  padding: 12px;
  // border-radius: 10px;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.05);
  // margin-right: 12px;
  min-width: 250px !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  .segmented {
    background-color: #f5f7fb;
    margin-bottom: 12px;
    :global(.ant-segmented-item-label) {
      color: @navy1;
      padding-block: 2px;
      white-space: pre-line;
      line-height: 22px;
    }
    :global(.ant-segmented-item-selected),
    :global(.ant-segmented-thumb-motion) {
      background-color: @main-color;
      :global(.ant-segmented-item-label) {
        color: #fff;
      }
    }
    :global(.ant-segmented-thumb-motion-appear-active) {
      transition-duration: 0.01s;
    }
  }
  .options {
    max-height: 415px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 6px !important;
      height: 9px !important;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 10px;
    }
  }
}

.table {
  height: unset !important;
  :global(.ant-table-row-level-0) {
    :global(.ant-table-cell-with-append) {
      svg {
        margin-right: 10px;
      }
    }
  }
  :global(.ant-table-content) {
    &::-webkit-scrollbar {
      width: 6px !important;
      height: 9px !important;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 10px;
    }
  }
  :global(.ant-input-number-group-addon) {
    background-color: #f5f7fb;
  }
}

.footer-table {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 7px 7px 12px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  color: #2c3782;
  .text-color {
    color: #6576ff;
    font-size: 14px;
    font-weight: 700 !important;
    line-height: 32px;
  }
}

.custom_radio {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 15px;
  :global(.ant-radio-button-wrapper) {
    border-radius: 5px;
    border: none;
    text-align: center;
    background: rgba(243, 246, 251, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    min-width: 180px;
    height: 32px;
    line-height: 32px;
    &::before {
      display: none;
    }
    span {
      font-weight: 700 !important;
      text-transform: uppercase;
      font-size: 13px;
      color: rgba(44, 55, 130, 1);
    }
    &:hover {
      opacity: 0.8;
    }
  }
  :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
    background: #2C3782 !important;
    border-color: #2C3782 !important;
    opacity: 1 !important;
    span {
      color: #FFFFFF;
    }
  }
  :global(.ant-radio-button-wrapper-disabled) {
    span {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}

.addBtn {
  margin-bottom: 10px;
  span {
    font-size: 15px;
  }
}

.tree {
  margin-left: -5px;
  :global(.ant-tree-title) {
    color: #2c3782;
  }
  :global(.ant-tree-node-selected) {
    background-color: #fff !important;
  }
  .disabled {
    span {
      color: rgba(0, 0, 0, 0.25) !important;
      cursor: not-allowed !important;
    }
    :global(.ant-tree-checkbox-inner) {
      background-color: #f5f5f5;
      border-color: #d9d9d9 !important;
      &::after {
        border-color: rgba(0, 0, 0, 0.25) !important;
      }
    }
    :global(.ant-tree-node-content-wrapper):hover {
      background-color: transparent;
    }
    :global(.ant-tree-checkbox-checked)::after {
      border: 1px solid #d9d9d9;
    }
  }
}

.modal {
  margin: 0 20px;
  padding: 15px;
  background: rgba(245, 247, 251, 1);
  border-radius: 15px;
  color: rgba(44, 55, 130, 1);
  p {
    line-height: 17.5px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  span {
    font-weight: 700 !important;
    font-size: 14px;
  }
  .modalChild {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin-bottom: 0 !important;
    }
    :global(.ant-input-number) {
      // width: 100%;
      background: #fff;
    }
  }
}

.tabs {
  :global(.ant-tabs-nav) {
    padding: 10px 20px;
    background: #F5F6FA;
    border-radius: 10px;
    &::before {
      display: none;
    }
  }
  :global(.ant-tabs-tab) {
    padding: 0 0 8px;
  }
  :global(.ant-tabs-tab-btn) {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700 !important;
    color: #2C3782;
    &:hover {
      color: #6576ff;
    }
  }
}

.usageDay {
  margin-top: 15px;
  input {
    background: #fff;
  }
  :global(.ant-form-item) {
    margin-bottom: 0 !important;
    label::after {
      content: "";
    }
  }
  .flex {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #2c3782;
    label {
      white-space: nowrap;
    }
  }
}

.headerTable {
  height: 48px;
  padding: 0 15px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    text-transform: uppercase;
    font-size: 16px;
    margin: 0 !important;
    color: rgba(44, 55, 130, 1);
  }
  :global(.ant-form-item) {
    margin: 0;
    label::after {
      content: "";
    }
  }
}
.contentTable {
  padding: 10px 0 0 10px;
  background-color: #F4F7FF;
}