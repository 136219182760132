@import "../../../../../assets/style/mixins.less";

.wrapper {
  border: 1px solid #e2e2e2;
  border-radius: 5px 5px 0 0;
  padding: 15px;
  padding-bottom: 0;
  margin-top: 5px;

  :global(.ant-input-group-addon) {
    background-color: #f5f7fb;
    color: #aaafcf;
    text-transform: lowercase;
    border-left: 1px solid #e4e6f8;
  }

  :global(.ant-select-selection-item) * {
    color: #fff;
  }
}

.layout-container {
  height: calc(100vh - 120px) !important;
  // border-bottom: 1px solid #e9e9e9;
  background-color: #fff;

  .title {
    margin: 15px;
    margin-bottom: 0;
    h3 {
      border-left: 1px solid #f3f6f9;
      color: #2c3782;
      font-weight: bold !important;
    }
    :global(.ant-select-selector) {
      background: #fff !important;
      min-width: 170px;
    }
  }
  .table-container {
    overflow: scroll;
    height: calc(100vh - 150px) !important;
    background-color: #f3f6f9;
    padding: 10px 10px;


    /* width */
    &::-webkit-scrollbar {
        width: 6px;
    }
    /* Handle */
    &::-webkit-scrollbar-track {
        color: transparent;
        background-color: transparent;
        &:hover {
            background-color: #ddd;
          }
    }

    &::-webkit-scrollbar-thumb {
      background: #f6f8fe;
      &:hover {
        background-color: #a7aab1;
      }
      transition: all 1s linear;
    }
    
  }

  .sider {
    background-color: #fff;
    height: 100%;
    overflow: auto;
    // border-right: 1px solid #e9e9e9;


    .input-container {
      padding: 10px;
      border-bottom: 1px solid #ececec;
      background-color: #fff;
      z-index: 1;

      .select-input {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .service-container {
      padding: 10px;
      padding-bottom: 0;
      height: calc(100vh - 294px);
      overflow: auto;

      :global(.ant-tree-indent) {
        display: none !important;
      }

      :global(.ant-tree-switcher-noop) {
        display: none !important;
      }
      :global(.ant-tree-title) {
        font-size: 13px !important;
        font-weight: bold !important;
        color: #2c3782 !important;
      }

      .block-container {
        // padding-bottom: 15px;
        border-bottom: 1px solid #d8d8d8;
        overflow: hidden;
        background: #fff;
        border: 0px;
      }

      .subclinicalList {
        font-size: 14px;
        font-weight: bold !important;
        color: #2c3782;
        border-bottom: 0px;
      }

      .subclinical-item {
        :global(.ant-tree-checkbox) {
          margin-left: 10px !important;
        }

        :global(.ant-tree-title) {
          font-size: 13px !important;
          font-weight: 500 !important;
          color: #2c3782 !important;
        }
      }
    }
  }
}

.green-tag {
  font-size: 14px;
  padding: 3px 10px;
  color: @green1 !important;
  background-color: @green2 !important;
  border-color: @green1 !important;
  font-weight: 600 !important;
  margin-top: -5px;
  margin-bottom: -5px;
}

.red-tag {
  .green-tag;
  color: @red1 !important;
  background-color: @red2 !important;
  border-color: @red1 !important;
}

.form-input-ctn {
  // background-color: #f5f7fb;
  // position: relative;
  // padding: 5px 15px;
  // padding-bottom: 0;
  // margin-top: 5px;

  .form-input {
    // background-color: #fff;
    // padding: 10px 15px;
    // border-radius: 05px;

    :global(.ant-form-item) {
      margin-bottom: 8px;
    }

    input:disabled,
    textarea:disabled {
      color: #0c133a !important;
    }

    svg {
      transform: rotate(270deg);
      vertical-align: 0px !important;
    }
    :global(.ant-collapse-content-box) {
      padding: 6px 60px 15px 20px;
    }
    :global(.ant-collapse) {
      background-color: #ffffff;
      border: none;
    }
    :global(.ant-collapse-item) {
      border: none;
    }

    :global(.ant-collapse-item-active) {
      svg {
        transform: rotate(0);
      }
    }

    :global(.ant-collapse-content) {
      border-top: 1px solid #e9e9e9;
    }

    :global(.ant-collapse-header) {
      font-weight: 600 !important;
      font-size: 18px !important;
      line-height: 22px !important;
      color: #2c3782 !important;
    }
  }
}

.form-ctn {
  background-color: #f5f7fb;
  position: relative;
  padding: 15px;
  padding-bottom: 0;
  margin-top: 5px;
}

.table-ctn {
  margin: 15px;
  background-color: #f5f7fb;
  position: relative;
  max-height: 90vh;
  min-height: 324px;
  overflow: scroll;
}

.table-wrapper {
  min-width: 1370px;
  display: block;
}

.table-ctn-no-adding {
  margin: 15px;
  margin-bottom: 0;
  overflow: scroll;
}

.form-note {
  padding-top: 0px;
  margin: 0px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;

  :global(.ant-form-item) {
    margin-bottom: 0px;
  }
  :global(.ant-form-item-label) {
    & > label {
      font-size: 12px;
    }
  }
  :global(textarea.ant-input) {
    background-color: #ffffff;
  }
}

.blue-txt {
  color: @navy1;
}

.select-w {
  width: 200px;

  :global(.ant-select-selector) {
    background-color: #fff !important;
    border-color: #d8d8d8 !important;
  }
}

.del-icon {
  filter: invert(41%) sepia(81%) saturate(823%) hue-rotate(322deg) brightness(92%) contrast(109%);
  cursor: pointer;
  width: 18px;
  margin-top: 3px;
  margin-bottom: -3px;
}

.total-price-ctn {
  padding: 10px 15px;
  text-align: end;
  // margin-bottom: 10px;
  // margin-top: -15px;
  color: @navy1;
  font-weight: 600 !important;
  font-size: 14px !important;

  .total-price {
    color: @green1;
    font-weight: 600 !important;
    font-size: 14px !important;
  }
}

.green-txt {
  color: @green1;
}

.empty {
  background-color: #fff;
  margin: 0;
  height: calc(100vh - 470px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: sticky;
  left: 0;
}

.collapse-panel {
  :global(.ant-collapse-header-text) {
    font-size: 16px;
    font-weight: 600;
  }
}
.action-button{
  display: flex;
  justify-content: space-between;
  padding-left: 300px;
  align-items: center;
  .form {
    width: 660px;
    display: flex;
    :global(.ant-form-item) {
      margin: 0;
    }
  }
}

.btn {
  border-radius: 5px;
  background: #f5f7fb;
  border: none;
  color: #999;
  &:hover {
    background: #f5f7fb;
  }
}

.btn-active {
    background-color: #6576FF;
    color:#fff;
    &:hover {
    background-color: #6576FF;
  }
}

.row-hightlight {
  background-color: #f6a447 !important;
}