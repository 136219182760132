.container {
  background-color: #fff;
  box-shadow: 0 0 5px #00000025;
  border-radius: 5px;
  width: 60vw;

  .title {
    padding: 13px 15px;
    margin-bottom: 0;
  }

  .table-ctn {
    border-top: 1px solid #00000010;
  }
}
