@import "../../../../assets/style/mixins.less";
.container {
  height: 100vh;
  //background-color: green;
}
.wrapper {
  padding: 10px 0;
  border-bottom: 1px solid #f0f0f0;

  .label {
    color: #999;
    font-size: 12px;
    margin-top: 10px;
    padding: 0 25px;
  }

  .inner {
    padding: 0 25px;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .item {
    margin-top: 5px;
    margin-bottom: 0;
    padding: 10px 25px;
    transition: all 0.1s ease-in-out;

    &:last-child {
      margin-top: 0;
    }

    svg {
      margin-right: 10px;
    }

    &.hover {
      cursor: pointer;

      &:hover {
        background-color: @navy1;

        .txt {
          color: #fff;
        }

        svg {
          filter: brightness(200%);
        }
      }
    }
  }
}
.table {
  padding: 15px;
}
.tag {
  background-color: @green2 !important;
  border: none !important;
  // border-color: @green1 !important;
  color: @green1 !important;
  cursor: default;
  font-weight: 600 !important;
  padding-block: 2px;
  margin-left: 5px;
}
.pagination {
  margin-top: 20px;
  // justify-content: flex-end;
  // background-color: #fff;
  // padding: 10px;
  border-radius: 5px;
  // box-shadow: 0 0 4px #00000015;

  button {
    background-color: #f6f9fc !important;

    * {
      color: @navy1 !important;
    }

    &:hover {
      background-color: #f0f0f0 !important;
    }
  }

  button:disabled {
    background-color: #f6f9fc !important;

    * {
      color: #cecece !important;
    }
  }
}

.navy-txt {
  color: @navy1;
}
.more-btn {
  transition: all 0.4s ease-in-out;
  margin-left: 10px;

  &:hover {
    background-color: #d4d8f4 !important;

    svg {
      color: @navy1 !important;
    }
  }

  transition-duration: 0.2s;
  height: 34px !important;
  width: 20px !important;
  padding: 0 !important;

  svg {
    transition: all 0.2s ease-in-out;
    color: #999;
  }

  &:disabled {
    background-color: transparent !important;
  }
}
.header {
  padding: 0 15px !important;
  top: @header-height;
  justify-content: space-between;
}
.popup {
  margin-top: 10px;
  min-width: 170px;

  :global(.ant-popover-inner-content) {
    padding: 0;
  }

  .wrapper {
    padding: 10px 0;
    border-bottom: 1px solid #f0f0f0;

    .label {
      color: #999;
      font-size: 12px;
      margin-top: 10px;
      padding: 0 25px;
    }

    .inner {
      padding: 0 25px;
      margin-top: 15px;
      margin-bottom: 5px;
    }

    .item {
      .inner;
      margin-top: 5px;
      margin-bottom: 0;
      padding-block: 10px;
      transition: all 0.1s ease-in-out;

      &:last-child {
        margin-top: 0;
      }

      svg {
        margin-right: 10px;
      }

      &.hover {
        cursor: pointer;

        &:hover {
          background-color: @navy1;

          .txt {
            color: #fff;
          }

          svg {
            filter: brightness(200%);
          }
        }
      }
    }
  }
}

.datePicker {
  border: 1px solid #d6d6d6;
}
.timeSelect {
  margin-top: 22px;
}

.tag-red {
  background-color: @red2;
  border-color: @red1;
  padding: 5px 10px !important;
  color: @red1 !important;
  font-size: 12px;
  font-weight: 600 !important;
}
.tag-green {
  background-color: @green2;
  border-color: @green1;
  padding: 5px 10px !important;
  color: @green1 !important;
  font-size: 12px;
  font-weight: 600 !important;
}

.table-phieu{
  :global(.ant-table-thead){
    :global(.ant-table-cell){
    background-color: #f3f6f9;
    }
  }
}

.btn-cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.8;
  }
}

.httt {
  width: 25px;
  height: 25px;
  background: #6576ff;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  line-height: 25px;
}

.cancelRes {
  margin: 0 20px;
  padding: 15px;
  background: rgba(245, 247, 251, 1);
  border-radius: 15px;
  color: rgba(44, 55, 130, 1);
  p {
    text-align: center;
    line-height: 17.5px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700 !important;
  }
  label {
    font-weight: 600 !important;
    font-size: 14px;
  }
  :global(.ant-form-item) {
    margin: 0;
  }
  input {
    background: white !important;
  }
}