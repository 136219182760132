@import "../../../../../assets/style/mixins.less";

.form-row {
  display: flex;
  // background-color: #fff;
  // padding: 15px;
  padding-bottom: 0px;
  gap: 10px;

  input {
    background-color: #fff;
  }
  
  :global{
    .ant-select-selector,.ant-select-in-form-item, .ant-form-item-control-input,.ant-input-affix-wrapper{
      background-color: #ffffff !important;
    }
    .ant-form-item {
      margin: 0;
    }
  }

  .form-col {
    flex: 20%;
  }
}

.form-wrap-row {
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 5px;
  overflow: hidden;



  .thong-tin {
    color:#2C3782;
    font-size: 18px;
  }
  :global{
    .ant-collapse,.ant-collapse-item{
      background-color: #F5F7FB;
    }

    .ant-collapse-header {
      align-items: center !important;
    }
  }

  :global{
    .ant-select-selector,.ant-select-in-form-item,.ant-form-item-control-input,.ant-input-affix-wrapper{
      background-color: #ffffff !important;
    }
  }
}

.form-ket-luan {
  // margin-top: 40px;

  :global {
    .tox-sidebar-wrap {
      height: 200px
    }

    .tox-toolbar {
      border-top: 1px solid #dfd9d9 !important;
    }
  }
  // height: 200px;
  padding: 15px;
  padding-bottom: 8px;
  background-color: #F5F7FB;
  margin: 0 12px 8px;
  border-radius: 6px;
  textarea {
    resize: none;
    background-color: #ffffff;
    font-weight: bold !important;
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
}

.text-editor-wrapper {
  position: relative;
  padding:10px;
  margin: 12px;
  // background-color: #fff !important;
  background-color: #F5F7FB;
  height: 575px;
  :global {
    .tox-menubar {
      border-top: 1px solid #dfd9d9 !important;
    }
  }
  .title{
    color: hsl(230 49% 34%);
    font-size: 14px;
    font-weight: 700;
    padding-bottom:12px;
  }
  :global(.tox-editor-container) {
    background-color: #fff !important;
  }

  .btn-docx {
    position: absolute;
    top: 7px;
    right: 15px;
    z-index: 1;
  }

  .text-editor, .text-editor-mota {
    // height: 100% !important;
    height: calc(100% - 24px)!important;

    // :global(.ql-container) {
    //   min-height: 200px;
    //   overflow: auto;
    //   max-height: calc(100vh - (@header-height * 3 + 265px));
    // }

    :global(.tox-mbtn) {
      height: 20px;
    }
    :global(.tox-menubar) {
      background: none;
      border-bottom: 1px solid #dfd9d9 !important;
    }
    :global(.tox .tox-toolbar) {
      background: none;
      border-bottom: 1px solid #dfd9d9 !important;
    }

    :global(.tox.tox-tinymce) {
      height: 100%;
    }

    :global(.tox-edit-area__iframe) {
      background-color: #ffffff !important;
    }

    :global(.tox-sidebar-wrap) {
      min-height: 200px;
      position: relative;
      // overflow: auto;
      // max-height: calc(100vh - (@header-height * 3 + 265px));
      // padding-top: 20px;
      // margin: 12px;
      background-color: #fff;
      border-radius: 6px;
      iframe {
        border-radius: 6px;
        border: none !important;
        background-color: #f5f7fb !important;
      }
      // &::after {
      //   content: "Mô tả";
      //   position: absolute;
      //   top: 10px;
      //   left: 15px;
      //   font-size: 15px;
      //   font-weight: 700;
      //   color: @navy1;
      //   background-color: #fff;
      //   width: 100px;
      //   padding-bottom: 5px;
      //   // margin-top: 10px;
      //   // margin-left: 10px;
      // }
    }

    &.read-only {
      :global(.tox-editor-header) {
        display: none;
      }
      pointer-events: none;
    }


    :global(.tox-tinymce--disabled) {
      .tox-menubar {
        display: none;
      }
    }

  }

  .text-editor-mota {
    height: 55vh !important;
  }
}

.popup-services {
  :global(.ant-select-tree-switcher) {
    span {
      display: none;
    }
  }
}
