@import "../../assets/style/mixins.less";
.unCheckMore {
  svg{
     * {
     stroke : #6576ff !important;
     }
  }
}

.topbar {
  align-items: stretch;
  justify-content: space-between;
  position: sticky;
  top: @header-height;
  z-index: 2;

  .tab-item {
    text-transform: uppercase;
    color: #999999;
    font-weight: bold !important;
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: 2px solid rgb(247, 251, 254);
    cursor: pointer;

    &.active {
      color: @navy1;
      border-bottom-color: @orange1;
    }
  }
}
// .orange-checkbox > span :hover{
//   // color: red;
//   // background-color: red;
//   border-color: #f6a447;
// }
.test>svg{
    width: 15px;
    height: 15px;

}
.test{
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
}

.table-container {
  padding: 15px;
}

.tag {
  font-size: 14px;
  padding: 5px 15px;
  margin-top: -5px;
  margin-bottom: -5px;
}

.red-tag {
  .tag;
  background-color: #ffdada;
  border-color: @red1;
  color: @red1 !important;
}

.green-tag {
  .tag;
  background-color: #e9fffd;
  border-color: @green1;
  color: @green1 !important;
}

.appoint-status {
  display: flex;
  align-items: center;
  gap: 10px;

  .icon {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    font-size: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &.orange {
      background-color: @orange1;
    }
    &.blue {
      background-color: @main-color;
    }
    &.red {
      background-color: @red1;
    }
    &.green {
      background-color: @green1;
    }
  }

  .content {
    color: @navy1;
  }
}
.datePicker {
  border: 1px solid #d6d6d6;
}
.timeSelect {
  margin-top: 22px;
}

.blue-icon {
  color: @main-color;
}

.searchbar-ctn {
  .searchbar {
    background-color: #fff;
    border-color: #ebebeb;
    height: 33px;

    :global(.ant-input) {
      background-color: #fff;
    }
  }

  @media screen and (max-width: 1300px) {
    display: none;
  }
}

.searchbar-responsive {
  display: none;

  @media screen and (max-width: 1300px) {
    display: block;
  }
}

.container-mau-dich-vu {
  .header {
    border-bottom: 1px solid #ebebeb;
    padding:0 12px;
  }

  .layout {
    display: flex;
    flex-direction: row;
    
  }

  .layout-sider {
    display: block;
    overflow: auto;
    min-height: 300px;
    max-height: 550px;
    .style-scroll-bar;

    .layout-sider-wrapper {
      padding: 16px 12px;
      overflow: auto;
      max-height: 470px;
      .style-scroll-bar;
    }
    .sider-item {
      cursor: pointer;
      margin: 0 5px;
      padding: 5px 0;
      &:hover {
        background-color: @blue2 !important;
      :global(.navy-txt) {
        color: #fff !important;
      }

      svg {
        path {
          fill: #fff !important;
        }
      }
      }
    }
    .sider-item-active {
        background-color: @blue2 !important;
        :global(.navy-txt) {
          color: #fff !important;
        }
        svg {
          path {
            fill: #fff !important;
          }
        }
    }
  }

  .layout-content {
    display: block;
    min-width: 580px;
    padding: 8px;
    position: relative;
  }
  .layout-footer {
    position: absolute;
    width: calc(100% - 20px);
    padding: 8px 4px;
    margin-top: 12px;

    bottom: 0;
  }
}

.popup-classname {
  :global(.ant-popover-inner) {
    border-radius: 18px !important;
  }
  :global(.ant-popover-inner-content) {
    padding: 12px 0;
  }
}

.new {
  background: #E63634;
  border-radius: 100px;
  color: #fff;
  font-size: 10px;
  width: 32px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}