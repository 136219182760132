@import "../../../../../assets/style/mixins.less";
.wrapper-sider {
    padding: 12px 10px 12px 0;
    // overflow: hidden;
    overflow-y: scroll;
    // height: calc(100vh - 135px);
    height: calc(100vh - 180px);
    border-right: 1px solid #cecece;
    &::-webkit-scrollbar {
      width: 6px !important;
      height: 6px !important;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(186, 192, 198);
      border-radius: 10px;
    }
    .tree {
      :global(.ant-tree-title) {
        color: #2c3782;
      }
      :global(.ant-tree-node-selected) {
        background-color: #fff !important;
      }
      .disabled {
        span {
          color: rgba(0, 0, 0, 0.25) !important;
          cursor: not-allowed !important;
        }
        :global(.ant-tree-checkbox-inner) {
          background-color: #f5f5f5;
          border-color: #d9d9d9 !important;
          &::after {
            border-color: rgba(0, 0, 0, 0.25) !important;
          }
        }
        :global(.ant-tree-node-content-wrapper):hover {
          background-color: transparent;
        }
        :global(.ant-tree-checkbox-checked)::after {
          border: 1px solid #d9d9d9;
        }
      }
    } 
  }

  .sider-checkbox-group {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    :global(.ant-checkbox-wrapper) {
        margin-left:0;
    }
  }

  .note-container {
    margin-top: 12px;
    span {
      font-weight: bold;
      font-size: 15px;
      color: #2c3782;
    }
    .note {
      background-color: #FFF;
     
    }
  }

  .footer {
    background-color: #fff;
    display: flex;
    position: sticky;
    bottom: 0;
    right: 0;
    width: 100%;

    align-items: center;
    justify-content: space-between;
    height: @header-height;
    padding-inline: 15px;
    z-index:10;
  
    @media only screen and (max-width:1200px){
      button {
        padding: 2px 5px;
        span {
          font-size: 12px;
        }
      }
    }
  }
