@import "../../../../assets/style/mixins.less";

.container {
  border-right: 1px solid #e7e7e7;


}

.wrapper {
  padding: 10px;
  padding-bottom: 0px;

  &:last-child {
    padding-bottom: 10px;
  }

  &.list {
    overflow: auto;
  }

  &.tab-kham-benh {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: fit-content;

    @media only screen and (min-height:700px) {
      max-height: (calc(100vh - 282px));
    }
   
    @media only screen and (max-height:700px) {
      max-height: (calc(100vh - 282px));
    }

    @media only screen and (max-height:600px) {
      max-height: 280px;
    }
    &.open-ttbn {
      @media only screen and (min-height:700px) {
        max-height: (calc(100vh - 282px));
      }
     
      @media only screen and (max-height:700px) {
        max-height: (calc(100vh - 282px));
      }
  
      @media only screen and (max-height:600px) {
        max-height: 280px;
      }    }
  }

  &.tab-dich-vu {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 10px;

    @media only screen and (min-height:700px) {
      max-height: (calc(100vh - 390px));
    }
   
    @media only screen and (max-height:700px) {
      max-height: (calc(100vh - 390px));
    }

    @media only screen and (max-height:600px) {
      max-height: 280px;
    }
    // min-height: fit-content;
    // min-height: 300px;
    // max-height: 300px;
    // &.open-ttbn {
      // height: calc(100vh - 580px);
    // }
  }
}

.segmented {
  background-color: #f5f7fb;

  * {
    white-space: pre-line;
    line-height: 1.2em;
  }

  :global(.ant-segmented-item-label) {
    color: @navy1;
    padding-block: 2px;
  }

  :global(.ant-segmented-item-selected),
  :global(.ant-segmented-thumb-motion) {
    background-color: @main-color;

    :global(.ant-segmented-item-label) {
      color: #fff;
    }
  }

  :global(.ant-segmented-thumb-motion-appear-active) {
    transition-duration: 0.01s;
  }
}
