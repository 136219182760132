@import "../../../assets/style/mixins.less";

.container{
  margin-top: 10px;
  //background-color: #FFF !important;
  margin-top: 0px;
  .container-content{
    //background-color: #FFF !important;
    //margin-left: 10px;
    padding: 10px;
  }
  :global(.ant-tabs-nav){
    margin: -1px 0px -1px 0px !important;
    background-color: #FFF !important;
    //padding:  0px !important;
  }
  :global(.ant-tabs-tab){
    margin: 0 0 0 10px !important;
  }
  :global(.ant-tabs-nav-wrap){
    background-color: #FFF !important;
    padding-left: 20px;
    border-bottom: 1px solid @grayLine !important;

  }
  :global(.ant-tabs-nav-list){
    background-color: #FFF !important;
    align-self: center;
    margin-left: -15px;
  }
  :global(.ant-tabs-extra-content){
      background-color:  #FFF !important;
  }
}

  .button{
    border: none;
    background-color: #F5F7FB !important;
    box-shadow: 0 0 5px #00000025;
    padding: 0px 50px !important;
  }
  .button-active {
    .button;
    background-color: @navy1 !important;
  }
  .title{
    font-size: 13px;
    color: @navy1;
    font-weight: 700 !important;
    
  }
  .title-active{
    .title;
    color: #FFF;
  }
