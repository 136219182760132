@import "../../../../assets/style/mixins.less";

.container {
  .content-container {
    padding: 10px 10px 0px 10px;

    :global(.ant-empty) {
      min-height: calc(100vh - 415px) !important;
    }
    // :global(.ant-table-body){
    //     min-height: calc(100vh - 330px) !important;
    // }
  }
  .footer-container {
    background-color: #fff;
    padding: 10px 20px;
  }
  .form-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    :global(.ant-form-item) {
      margin-bottom: 0px !important;
    }
    :global(.ant-col .ant-form-item-label) {
      padding-bottom: 0px !important;
    }
  }
  .button {
    width: 180px;
    margin-left: 10px;
  }
}

.item-add {
  position: absolute;
  width: 16px;
  height: 16px;

  text-align: center;
  top: 4px;
  right: 0;

  background-color: #434343;
  border-radius: 50%;
  color: #ccc;

  cursor: pointer;

  &:hover {
    background-color: #686868;
    color: #ececec;
  }
}
.preview-img {
  width: 100px;
  height: 100px;
}
.icon-wrapper {
  margin: auto;
}
.emty-img {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  display: flex;
  //margin-bottom: 10px;
  //margin-right: 11px;
  img {
    object-fit: cover;
    width: 100px;
    height: 100px;
  }
  &:nth-child(2) {
    margin-right: 0;
  }
  &:nth-child(4) {
    margin-right: 0;
  }
}
.button_table {
  min-width: 90px;
  max-width: fit-content;
  background-color: #6e75fc;
}

.table-custom {
  :global(.ant-empty) {
    // min-height: calc(100vh - 450px) !important;
    padding-top: 10%;
  }
  :global(.ant-empty-normal .ant-empty-image) {
    height: 100%;
  }
}
