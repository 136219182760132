@import "../../../../../assets/style/mixins.less";

.active-modal {
  padding: 24px 24px;
  width: 460px !important;
  border-radius: 10px;

  .title {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    line-height: normal;
  }

  .content {
    margin: 24px;
    font-size: 14px;
    padding: 24px 12px;
    border-radius: 10px;
    background: #F6F8FE;

    .content__row{
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        div {
          color: #2c3782;
        }
    }

    .date-input{
        height: 30px;
        border-radius: 10px;
        background: #FFF;
        margin-bottom: 0;

        :global(.ant-form-item-control-input){
            height: 30px!important;
            min-height: 30px!important;
        }
        :global(.ant-form-item-explain.ant-form-item-explain-connected){
            display: none!important;
        }
        :global(.ant-input-affix-wrapper){
            background: #fff;
            border-radius: 10px;
            height: 30px;
            display: flex;
            align-items: center;

            input{
                background: #fff;
            }
        }
        :global(.ant-input-suffix){
            display: flex;
            align-items: center;

            &>div{
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .calendar{
            width: 20px;
            height: 20px;
            filter: grayscale(1)
        }
    }
  }

  .btns {
    padding: 0 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .btn {
      width: 100%;
    }
  }

  :global(.ant-modal-close) {
    display: none;
  }
}


.text-fade{
  opacity: 0.5;
}