.sider {
  height: calc(100vh - 60px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.03);
  border: 0.5px solid #EBEBEB;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
  }

  :global(.ant-form-item) {
    padding: 12px;

    label {
      font-weight: 700 !important;
    }
  }

  :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    background-color: #f5f7fb;
  }

  :global(.ant-divider-horizontal) {
    margin: 0;
    border-color: #EFEFEF;
    border-width: 1.5px 0 0;
  }

  .search-wrapper {
    display: flex;
    gap: 8px;

    :global(.ant-input-affix-wrapper) {
      width: calc(100% - 41px);
    }
  }

  // .tabs {
  //   padding: 12px;
  //   :global(.ant-btn-text) {
  //     background: #F5F7FB;
  //     &:hover {
  //       opacity: 0.7;
  //     }
  //   }
  //   button {
  //     width: 100%;
  //     span {
  //       font-weight: 700 !important;
  //     }
  //   }
  // }

  .tabs2 {
    padding: 12px;
    :global(.ant-radio-button-wrapper) {
      width: 100%;
      height: 26px;
      line-height: 24px;
      span {
        color: #2C3782;
      }
    }
    :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
      background: #6576ff;
      border-color: #6576ff;
    }
  }

  .card-container {
    width: 100%;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    :global(.ant-card-body) {
      padding: 0;
      cursor: pointer;

      span {
        font-size: 12px;
        color: #2C3782;
      }

      p {
        color: #2C3782;
        font-size: 10px;
        margin-bottom: -3px;
        opacity: 0.699999988079071;
        font-weight: 500 !important;
      }

      :global(.ant-row) {
        padding: 4px 10px;
      }
    }

    .dot-red {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: red;
    }
    .dot-green {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #2cb2a5;
    }
  }

  .card-selected {
    background: #2C3782;

    :global(.ant-card-body) {

      span, h5 {
        color: #FFFFFF;
      }

      p {
        color: #FFFFFF;
        opacity: 0.699999988079071;
      }
    }

    :global(.ant-divider-horizontal) {
      opacity: 0.10000000149011612;
    }
  }
}


.custom_radio {
  display: flex;
  gap: 8px;
  :global(.ant-radio-button-wrapper) {
    border-radius: 5px;
    border: none;
    text-align: center;
    background: #F5F7FB;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &::before {
      display: none;
    }
    span {
      font-weight: 700 !important;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
    span {
      color: #FFFFFF;
    }
  }
}


.main {
  .header {
    height: 54px;
    padding: 8px 16px;
    display: flex;

    .container {
      width: 100%;
      height: 100%;
      justify-content: space-between;

      .tabs {
        :global(.ant-radio-button-wrapper) {
          width: 157px;
          height: 30px;
          line-height: 28px;
          span {
            font-size: 10px;
            color: #999999;
          }
        }
        :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
          background: #2C3782;
          border-color: #2C3782;
          span {
            color: #FFFFFF;
          }
        }
      }
    }
  }
  .right_sider {
    height: calc(100vh - 186px);
    // box-shadow: 0 4px 20px rgba(0, 0, 0, 0.03);
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 6px !important;
      height: 6px !important;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 10px;
    }
    h4 {
      margin: 15px 0 0 17px;
    }
    :global(.ant-divider-horizontal) {
      margin: 10px 0;
      border-color: #E4E4E4;
      border-width: 1.5px 0 0;
    }
    .money_container {
      width: 100%;
      padding: 0 15px;
      gap: 10px;
      :global(.ant-space) {
        width: 100%;
        justify-content: space-between;
        gap: 0;
        :global(.ant-space-item) {
          span {
            font-size: 12px;
            font-weight: 500 !important;
            color: #2C3782;
          }
          b {
            font-size: 14px;
            color: #2C3782;
          }
        }
        
      }
    }
    :global(.ant-form-item) {
      padding: 0 15px 2px;
      label {
        font-size: 12px;
        font-weight: 700 !important;
      }
      textarea {
        min-height: 88px;
      }
    }
    .change {
        width: 100%;
        justify-content: space-between;
        gap: 0;
        padding: 0 15px;
        :global(.ant-space-item) {
          span {
            font-size: 12px;
            font-weight: 500 !important;
            color: #2C3782;
          }
          b {
            font-size: 14px;
            color: #2C3782;
          }
        }
    }
  }
  .footer {
    background-color: #fff;
    padding: 20px;
    :global(.ant-space) {
      display: flex;
      justify-content: flex-end;
    }
    :global(.ant-btn-primary) {
      min-width: 120px;
    }
  }
}

.drawer {
  :global(.ant-drawer-close) {
    display: none;
  }
  :global(.ant-drawer-header-title) {
    padding: 15px 30px;
    h2 {
      font-size: 22px;
    }
  }
  :global(.ant-drawer-footer) {
    padding: 16px 24px;
    :global(.ant-btn) {
      min-width: 124px;
    }
  }
  .spaceDiv {
    width: 100%;
    justify-content: space-between;
    :global(.ant-input-affix-wrapper) {
      padding: 0 11px;
    }
    .title {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }
}

.payment-container {
  padding: 12px 0;
  border-radius: 10px;
  background: #F5F7FB;
  margin: 10px 13px 0;
  position: relative;
  input {
    background-color: #fff;
  }
  :global(.ant-select-selector) {
    background-color: #fff !important;
  }
  .delete-btn {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 10;
    &:hover {
      opacity: 0.8;
    }
  }
}
.btn-add {
  cursor: pointer;
  align-items: center;
  margin: 7px 14px;
  &:hover {
    opacity: 0.8;
  }
}
.btn-cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.8;
  }
}

.cancelRes {
  margin: 0 20px;
  padding: 15px;
  background: rgba(245, 247, 251, 1);
  border-radius: 15px;
  color: rgba(44, 55, 130, 1);
  p {
    text-align: center;
    line-height: 17.5px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700 !important;
  }
  label {
    font-weight: 600 !important;
    font-size: 14px;
  }
  :global(.ant-form-item) {
    margin: 0;
  }
  input {
    background: white !important;
  }
}

.more {
  padding: 12px;
  width: 100%;
}