.modal {
    :global {
        .ant-modal-body {
            padding: 0;
        }

        .ant-form-item {
            margin: 0;
        }

        .ant-form-item-label > label::after {
            content: ' ';
        }
    }

    .container {
        margin: 16px;
        padding: 12px;
        background-color: #F5F7FB;
        border-radius: 15px;
        // border-bottom: 1px #EFEFEF solid !important;

        input {
            background-color: #FFFFFF;
        }


        :global {
            .ant-form-item-label > label {
                height: unset !important;
                min-height: 32px;

                p {
                    margin : 0 !important;
                }
            }
        }
    }

    .classFooter{
        border-top: 1px #EFEFEF solid !important;
        padding: 12px;
        margin: 0 !important;
    }
}