@import "../../../assets//style/mixins.less";

.container{
   margin: 32px; 
}

.add-kho-container{
   margin: 16px;
   border-radius: 10px;
}
.switch-group{
}
.select{
   margin:auto;
   border-left: 1px dotted #ccc;
}