@import "../../../../assets/style/mixins.less";

.side-bar {
  height: calc(100vh - @header-height);
  overflow: auto;
  border-right: 1px solid #ebebeb;
  padding: 15px;
  position: sticky;
  top: @header-height;
}

.bottom-bar {
  background-color: #ffffff;
  height: @header-height;
  display: flex;
  align-items: center;
  padding-inline: 15px;
  border-top: 1px solid #ebebeb;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  border: none;

  z-index: 2;
}

.top-bar {
  padding-inline: 15px;
  // border-bottom: 1px solid #ebebeb;
  position: sticky;
  top: @header-height;
  z-index: 2;

  display: flex;
  justify-content: space-between;
  align-items: stretch;

  .plus-icon {
    color: @main-color;
    font-size: 22px;
  }
  .box-action {
    border-right: 1px solid #ebebeb;
    padding-right: 10px;
    margin-right: 10px;

    .text {
      color: #2c3782;
      margin-left: 10px;
    }
    //.delete-ion {
    //  fill: red;
    //}
  }
}

.tree-data {
  :global(.ant-tree-switcher.ant-tree-switcher-noop),
  :global(.ant-tree-indent) {
    display: none;
  }

  :global(.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal) {
    padding: 3px 20px;
    margin-left: 5px;

    * {
      font-size: 12px;
    }

    &:global(.ant-tree-node-selected) {
      background-color: @navy1;

      * {
        color: #fff !important;
      }
    }
  }

  :global(.ant-tree-title) {
    color: @navy1 !important;
  }
}

.node-title {
  font-weight: 700 !important;
  font-size: 16px !important;
}

.side-bar-mau-mo-ta {

  
  :global(.ant-tree-title) {
    font-size: 14px !important;
    font-weight: 500 !important;
  }

}
