@import "../../../../../../assets/style/mixins.less";
.row-title {
  background-color: #fff;
  padding: 0 0 0 15px;
  border-radius: 05px;
  margin-bottom: 20px;
  span {
    color: #2c3782;
    // font-size: 12px;
    font-weight: bold !important;
  }
  :global(.ant-col) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.row-table {
  background-color: #fff;
  padding: 0 0 0 15px;
  border-bottom: 1px solid #e2e2e2;
  // :global(.ant-row) {
  //   align-items: stretch;
  // }

  :global(.ant-col) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  span {
    color: #2c3782;
    font-size: 13px !important;
  }
  .icon {
    display: flex;
    justify-content: center;
    cursor: pointer;
    svg {
      filter: invert(24%) sepia(99%) saturate(7500%) hue-rotate(360deg) brightness(111%) contrast(118%);
      width: 20px;
      height: 20px;
      //margin-left: 10px;
    }
  }

  input:disabled {
    color: #2c3782;
  }
}

// .col {
//   padding: 15px;
// }

.green-tag {
  font-size: 13px;
  padding: 3px 10px;
  color: @green1 !important;
  background-color: @green2 !important;
  border-color: @green1 !important;
  font-weight: 600 !important;
  margin-top: -5px;
  margin-bottom: -5px;
}

.red-tag {
  .green-tag;
  color: @red1 !important;
  background-color: @red2 !important;
  border-color: @red1 !important;
}
.green-txt {
  color: @green1 !important;
  font-size: 13px !important;
}

.noDelete {
  justify-content: flex-start !important;
}

.fixed {
  position: sticky;
  align-self: stretch;
  right: 0px;
  z-index: 1;
  background-color: #fff;
  width: 85px;
  // padding-top: 10px;
  // padding-bottom: 10px;
  padding: 10px;
  margin-left: 20px;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  // flex: 1;
  &::after {
    position: absolute;
    top: 0;
    bottom: -1px;
    left: 0;
    width: 30px;
    transform: translate(-100%);
    transition: box-shadow 0.3s;
    content: "";
    pointer-events: none;
    box-shadow: inset -10px 0 8px -8px #00000010;
  }
}

.footer-table {
  // display: none;
  border-top: 1px solid #EFEFEF;
  padding: 0 0 0 15px;
  border-bottom: 1px solid #e2e2e2;
  background-color: #ffffff;
  :global(.ant-col) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  border-radius: 0px 0px 10px 10px;
  height: 44px;
  .column {
    padding-block: 7px;

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }
  }
}

.blue-txt{
  color: #6576FF;
}