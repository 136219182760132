@import "../../../../../assets/style/mixins.less";
.container {
    //height: 80vh;
    height:  calc(100vh - 110px);
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    :global(.ant-form-item) {
      margin-bottom: 5px !important;
    }
    :global(.ant-form-item-label){
      padding: 0px !important;
    }
    :global(.ant-form-item-explain.ant-form-item-explain-connected) {
      display: none !important;
    }
    :global(.ant-form.ant-form-inline){
        display: block !important;
    }
    :global(.ant-modal-close){
      display: none !important;
    }
    :global(.ant-picker) {
      background-color: #F5F7FB!important;
    }
    :global(.ant-col){
      padding-left: 5px;
    }
    :global(.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector){
      background-color: #F5F7FB!important;
    }
    :global(.ant-picker-input > input[disabled]){
      color:  @navy1 !important;
    }
    :global(.ant-select-disabled .ant-select-arrow){
      display: none !important;
    }
    :global(.ant-form-item-control){
      padding-left: 0px !important;
    }
    .input{
        border-color: transparent;
        // border-radius: 5px !important;
    }
    .infoDrug{
        // border: 1px solid @grayLine;
        // border-radius: 5px;
        margin-left: 15px !important;
        margin-right: 15px !important;
        padding-top: 15px !important;
        margin-top: -15px !important;
    }

    .payment{
        border-radius: 5px;
        box-shadow: 0 0 5px #00000025;
        //margin-left: 10px;
        padding: 10px;
        //overflow: auto !important;
        // background-color: red;
        //height: calc(100vh - 50vh);
        height: 100%;
    }
    .payment_readOnly{
      .payment;
    }

    .top-input {
        position: relative;
        height: 40px;
      }
      .button-group {
        position: absolute;
        display: flex;
        top: 2px;
        right: 12px;
        z-index: 2;
        align-items: center;
        .button-top {
          position: relative;
          font-size: 24px;
          overflow: hidden;
          top: 2px;
          border-radius: 12px;
          margin-left: 0.5rem;
          svg {
            color: @main-color;
          }
          &:hover {
            background-color: #e1e1ff;
          }
        }
    
      }
      .money {
        font-weight: 600 !important;
        font-size: 15px;
        color: @navy1;
      }
      .money_special{
        .money;
        color: @green1;
      }
      .money_special_red{
        .money;
        color: red;
      }
}
.del_icon {
  fill: #999999;
  cursor: pointer;
  width: 18px;
  margin-top: 3px;
  margin-bottom: -3px;
  &:hover {
    fill: #f34946;
  }
}