.container{
    :global(.ant-menu) {
        padding-top: 15px;
    }

    :global(.ant-menu-sub) {
        padding-top: 0;
    }
    :global(.ant-menu-submenu-title) {
        padding-left: 18px !important;
        margin: 0 !important;
    }

    :global(.ant-menu-item) {
        padding-left: 18px !important;
        margin: 0 !important;
    }

    :global(.ant-menu-inline) {
        background-color: #ffffff;
        border: 1px solid transparent;
    }

    :global(.ant-menu-item-only-child) {
        padding-left: 45px !important;
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
        color: #2C3782;
    }

    :global( .ant-menu-item-selected) {
        color: white;
      svg{
       filter:  brightness(980%) ;
    }  
    }
    :global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected){
        background-color: #2C3782;
    }
    :global(.ant-menu-item-selected):hover{
         color: white;
    }
    :global(.ant-menu-submenu-selected){
        color: #2C3782;
    }
    height: calc(100vh - 55px) ;
    background-color: white;
    position: sticky;
    left: 0px;
    top: 55px;
    overflow-y: auto;
}