@import "../../../../assets/style/mixins.less";

.container {
  height: calc(100vh - @header-height);
  background-color: #ffffff;
  overflow: auto;
  position: sticky;
  top: @header-height;
  box-shadow: 0 0 5px #00000025;
  z-index: 0;

  /* width */
  &::-webkit-scrollbar {
    width: 6px !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(186, 192, 198);
    border-radius: 10px;
  }

  .wrapper {
    padding: 14px;
    border-bottom: 1px solid #ededed;

    &:last-child {
      border-bottom: none;
    }

    &:first-child {
      border-bottom: 1px dashed #e0e0e0;
    }

    .search-icon {
      background-color: #f5f7fb;
      font-size: 20px;
      border: none;

      * {
        color: @main-color;
      }
    }
    .select-phong-container {
      max-height: 32px;
    }

    .tabs {
      margin-bottom: 15px;
      display: flex;
      width: 100% !important;

      * {
        font-size: 11px;
        //font-weight: 600 !important;
      }

      &:first-child {
        margin-left: -5px !important;
      }

      .button {
        background-color: #f4f6fa;
        margin-left: 5px;
        color: #2c3782;
        border: none;
        flex: 1;
      }

      .button-active {
        .button;
        background-color: #6576ff;
        color: #fff;
      }
    }
  }

  .patient {
    background-color: #fff;
    border-radius: 8px;
    border: 0.6px solid #eee;
    margin-top: 5px;
    padding-block: 5px;
    cursor: pointer;

    .inner {
      padding: 3px 15px;
      border-bottom: 1px dashed rgba(239, 239, 239, 1);
    }

    .title {
      color: #2c3782;
      opacity: 0.7;
      font-size: 10px;
      font-weight: 500px;
      line-height: 12px;
    }

    .content {
      color: #2c3782;
      font-size: 12px;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis ;
    }

    .flex {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 15px 3px;
      p {
        line-height: 1;
        color: #2c3782;
      }
    }
  }

  .patient-active {
    .patient;
    background-color: #2c3782;

    .inner {
      border-bottom: 1px dashed rgba(239, 239, 239, 0.3);
    }

    .title {
      color: #fff;
    }

    .content {
      color: #fff;
    }

    .flex {
      p {
        color: #fff;
      }
    }
  }
}

.popup-select {
  z-index: 0;
  // .ant-select-item .ant-select-item-option .ant-select-item-option-active .ant-select-item-option-selected{
  //   background-color: red !important;
  // }
  :global(.ant-select-item-option-selected){
    span, div {
      color : #FFF !important
    }
  }
}

// .select-phong-input{
  
// }
