.container {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .main {
    width: 73%;
    background-color: #fff;
    margin: auto;
    transform: translateY(50%);
    padding: 3vmin;
    border-radius: 5vmin;

    .inner {
      margin: auto;
      width: 63%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0vmin;
      text-align: center;

      .logo-wrapper {
        margin-bottom: 3vmin;
        cursor: pointer;

        svg {
          width: 26vmin;
          aspect-ratio: 1 / 0.5;
        }
      }

      h1 {
        margin-bottom: 1vmin !important;
        font-size: 2vw;
        font-weight: 700 !important;
        color: #000;

        span {
          font-size: inherit;
          color: #ff548e;
        }
      }

      p {
        font-size: 2vmin;
        font-weight: 600 !important;
        color: #333;
      }

      .btn-wrapper {
        background-color: #ff548e;
        border-radius: 10vmin;
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        margin-top: 4vmin;

        &:hover {
          box-shadow: 0px 15px 31px rgba(255, 84, 142, 0.5);
        }

        button {
          font-size: 2vmin;
          border: none;
          background-color: transparent;
          color: #fff;
          padding: 1vmin 2.5vmin 1vmin 5vmin;
          cursor: pointer;
          font-weight: 700 !important;
        }

        .arrow-wrapper {
          background-color: #fff;
          width: 4vmin;
          height: 4vmin;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.5vmin;

          svg {
            font-size: 1.5vmin;
          }
        }
      }
    }
  }

  .footer {
    color: #fff;
    display: flex;
    align-items: center;
    gap: 5vw;
    margin-bottom: 1vmin;
    width: 100%;
    justify-content: center;

    * {
      font-size: 0.8vw;
      font-weight: 700 !important;
    }
  }
}

.login-success {
  display: flex;

  height: 100vh;
  width: 100vw;

  .text-block {
    margin: auto;
    font-size: 16px;
  }
}

.logo {
  max-width: 200px;
  max-height: 150px;
}

// màn lớn
@media screen and (min-height: 771px) and (min-width:1100px){
  .logo {
    max-width: 18vw;
    max-height: 18vh;
  }
}

// màn siêu lớn
@media screen and (min-height: 900px) and (min-width:2000px){
  .logo {
    max-width: 20vw;
    max-height: 20vh;
  }
}

//màn dài trung bình
@media screen and (max-height: 767px) {
  .logo {
    max-width: 200px;
    max-height: 105px;
  }
  .main {
    padding: 2vmin !important;

  }
}

//màn ngắn
@media screen and (max-height: 531px) and (max-width:768px) {
  .logo {
    max-width: 71px;
    max-height: 71px;
  }
}