@import "../../../../../assets/style/mixins.less";
.row {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
  
    .column {
      display: flex;
      // width: 100%;
      align-items: center;
      border-bottom: 1px solid #eaeaea75;
    }
  }
  
  .head {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    z-index: 2;
    box-shadow: 0 0 10px #00000010;
  
    .column {
      padding-block: 7px;
  
      &:first-child {
        border-radius: 5px 0 0 5px;
      }
  
      &:last-child {
        border-radius: 0 5px 5px 0;
      }
    }
  }
  .center {
    display: flex;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    width: 100%;
  }
  .test > svg {
    width: 15px;
    height: 15px;
  }
  .test {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
  .column {
    background-color: #fff;
    padding: 5px;
  
    &.right {
      position: sticky;
      right: 0;
      z-index: 1;
  
      &::after {
        position: absolute;
        top: 0;
        bottom: -1px;
        left: 0;
        width: 30px;
        transform: translate(-100%);
        transition: box-shadow 0.3s;
        content: "";
        pointer-events: none;
        box-shadow: inset -10px 0 8px -8px #00000010;
      }
    }
  
    &.near-by-fixed {
      z-index: 1;
    }
  }
  
  .empty {
    background-color: #fff;
    margin: 0;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: sticky;
    left: 0;
  }
  .foot {
    display: flex;
    flex-wrap: nowrap;
    border-top: 1px solid #EFEFEF;
    border-radius: 0px 0px 10px 10px;
    height: 44px;
    .column {
      padding-block: 7px;
  
      &:first-child {
        border-radius: 5px 0 0 5px;
      }
  
      &:last-child {
        border-radius: 0 5px 5px 0;
      }
    }
  }


.table-add {

  :global(.ant-table-content){
      /* width */
  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(186, 192, 198);
    border-radius: 10px;
    }
  }

  :global(.ant-table-row-expand-icon) {
    border: none;
    &::after {
      content:none !important;
    }
  }
  :global(.ant-table-row-level-0) {
    :global(.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first)::after {
      box-shadow: none;
    }
    text-transform: uppercase;
    background: transparent;
  }
}


.red-row {
  border: 1px solid red !important;
  td {
  background-color: @orange1 !important;

  }
}