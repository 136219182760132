@import "../../../../assets/style/mixins.less";

.action-btn {
  display: flex;
  align-items: center;
  gap: 5px;
}

.action-btn-green {
  .action-btn;
  background-color: @green1 !important;
  border-color: @green1 !important;
}

.danhsach-lichsukham {
    height: calc(100vh - @header-height - 41px );
    background-color: #F5F7FB;
    padding: 15px 15px 0px 15px;
}
.lichsu-kham-container {
  padding: 10px;
  //background-color: #F5F7FB;
}
