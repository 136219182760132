.wrapper {
  :global(.ant-modal-body) {
    padding: 62px 40px 40px 40px;
  }
}

.title-content {
  margin-top: 23px;
  margin-bottom: 24px;
  h1 {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #333333;
    margin-bottom: 4px;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #666666;
  }
}

.btn {
  display: flex;
  width: 100%;
}
