.container{
    :global(.ant-modal-body){
        padding: 14px 24px;
    }

    :global(.tui-image-editor-header-buttons) {
        display: none !important;
    }

    :global(.tui-image-editor-header-logo) {
        display: none !important;
    }

}
.edit-container{
    padding-bottom: 14px;
    :global(.tui-image-editor-wrap){
        &::-webkit-scrollbar {
            width: 6px !important;
            height: 6px !important;
          }
        
          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: rgb(186, 192, 198);
            border-radius: 10px;
          }
    }
}

.list-container{
    padding: 12px 0;

}

.image-item{
    position: relative;
    padding: 10px;

    :global(.ant-image){
        aspect-ratio: 1;
        img{
            height: 100%;
            object-fit: cover;
        }
    }

    .empty-item{
        width: 100%;
        aspect-ratio: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed #d9d9d9;
    }
}

.image-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
}