@import "../../../../../assets/style/mixins.less";
.container {
  //padding: 2rem;
  // :global(.actionButton_icon-delete-btn__3E3gO){
  //   margin-left: 4px !important;
  // }
}

.text-ellipsis {
  display: block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bold {
  font-weight: bold !important;

  font-size: 14px;
  line-height: 18px;

  color: @navy1;
}
.star {
  margin-top: -10px;
  font-size: 20px;
  :global(.anticon .anticon-star) {
    font-size: 30px;
  }
}
.row-side {
  padding: 0 12px;

  .row-item {
    color: @navy1;
    margin-bottom: 8px;
  }
}
.radio-group {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  justify-content: space-evenly;
}

.tag-red {
  font-size: 12px;
  font-weight: 600 !important;
  text-align: center;
  color: @red1 !important;
}
.tag-green {
  // background-color: @green2;
  // border-color: @green1;
  // padding: 5px 10px !important;
  color: @green1 !important;
  font-size: 12px;
  font-weight: 600 !important;

  text-align: center;
}
.tag-yellow {
  background-color: #6576ff;
  padding: 5px 10px !important;
  color: white !important;
  font-size: 12px;
  font-weight: 600 !important;
  text-align: center;
  border-radius: 5px;
  // pointer-events: none;
}
.disabled{
  opacity: 0.6;
  cursor: not-allowed;
}
.tag-disable {
  background-color: @grayLine;
  border-color: @textgray;
  padding: 5px 10px !important;
  color: @textgray !important;
  font-size: 12px;
  font-weight: 600 !important;
  width: 120px;
  text-align: center;
  cursor: not-allowed;
}
