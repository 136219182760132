.form-container {
  margin-top: 15px;
  // height: calc(100vh - @header-height * 2 - 175px);
  overflow: auto;
  padding-inline: 15px;
  :global(.ant-form-item-label) {
    padding-bottom: 5px !important;
  }
}

.label {
  font-size: 14px;
  // font-weight: 700 !important;
  line-height: 32px;
  color: #2c3782;
}

.noMinH {
  :global(.ant-form-item-control-input) {
    min-height: unset !important;
  }
}

.wrapper {
  background: #f5f7fb;
  padding: 5px 20px;
  // border: 1px solid #d9d9d9;
  border-radius: 10px;
  margin: 0 5px 6px;
  :global(.ant-form-item) {
    margin-bottom: 0 !important;
  }
}