@import "../../../../../assets/style/mixins.less";

.container {
  background-color: #fff;
  //padding: 10px 15px;
  border-radius: 0px 5px 0px 0px;
  height: 150px;

  label {
    font-size: 12px;

    &::after {
      content: "";
    }
  }

  h4 {
    margin-bottom: 0px !important;
  }
  
}

.left-thongTin {
  background-color: #FFF;
  height:  calc(100vh - @header-height * 2 - 50px);
  border-right: 1px solid #E0E0E0;
  padding: 10px;

  .button {
    background-color: #F4F6FA;
    color : @navy1;
    min-width: 130px;
    max-width: 180px;
    height: 28px;
    border: none;
    margin-right: 10px;
  }
  .button-active {
    .button ;
    background-color: #6576FF;
    color : #FFF
  }
  .icon-edit {
    fill: #999999;
    width: 25px;
    height: 25px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    margin-right: 10px;
  }
}

.popup-ctn {
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 0 0 10px #00000025;
  width: 850px;
  overflow: hidden;
  max-height: calc(100vh - @header-height - 130px);
  h4 {
    margin-bottom: 0px !important;
  }

  .title,
  .header {
    padding: 10px 15px;
  }

  .sider {
    border-right: 1px solid #ededed;
  }

  .title {
    border-bottom: 1px solid #ededed;
    height: 45px;
  }

  .header {
    height: 45px;
    background-color: #fff;
  }

  .content {
    //padding: 15px;
    
  }

  .footer {
    height: 70px;
    margin-bottom: -15px;
    padding: 15px;
    padding-inline: 15px;
    display: flex;
    align-items: center;
    background-color: #fff;
    z-index: 1;
    justify-content:space-between;
    border-top: 1px solid #E0E0E0;

    * {
      margin: 0;
    }
    position: sticky !important;
    bottom: 0px !important;
    
    .title-input{
      color: @navy1;
      font-size: 14px;
    }
    .title{
      font-weight: "700";
      font-size: "13px";
      color: @navy1
    }
  }
}

.menu {
  box-shadow: none;
  max-height: calc(100vh - @header-height * 2 - 175px);;
  overflow: auto;

  .icon-delete {
    fill: #999999;
    width: 18px;
    margin-block: 3px -3px;
    transition: all 0.2s ease-in-out;
  }

  :global(.ant-dropdown-menu-item-active) {
    background-color: @main-color;
    color: #fff;

    .icon-delete {
      //fill: @main-color;
    }
  }
}

.modal-xoa-don-mau {
  // display: none;

  :global(.ant-modal-mask),
  :global(.ant-modal-wrap) {
    z-index: 1051;
    display: none;
  }
}
//style lại form thông tin hỏi bệnh
:global(.ant-form-item) {
  margin-bottom: 8px;
  flex-direction: column;
}
:global(.ant-form-item-label) {
  align-self: flex-start;
}

.btn-icon-delete {
  border: none;
}

.form {
  margin: 15px 25px 5px;
  :global(.ant-form-item-label) {
    padding: 0 0 5px !important;
  }
  :global(.ant-form-item) {
    margin-bottom: 12px !important;
  }
  :global(.ant-picker) {
    background: #f5f7fb;
    width: 100%;
  }
  .wrapper {
    padding: 0 15px 10px;
    background: #F5F6FA;
    border-radius: 10px;
    margin: 5px 0 12px;
  }
}

.modal {
  h1 {
    text-align: center;
    margin-bottom: 16px;
  }
  .modalBox {
    margin: 0 20px;
    padding: 15px;
    background: rgba(245, 247, 251, 1);
    border-radius: 15px;
    color: rgba(44, 55, 130, 1);
    p {
      line-height: 17.5px;
      margin-bottom: 10px;
      font-size: 14px;
      opacity: 0.5;
      text-align: center;
    }
    .modalChild {
      display: flex;
      flex-direction: column;
      gap: 2px;
      span {
        color: rgba(44, 55, 130, 1);
      }
    }
  }
  .footer {
    margin-top: 24px;
    padding: 0 20px;
  }
}
