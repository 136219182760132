
.main {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .section {
    display: flex;
    max-width: 905px;
    // align-items: center;
    // justify-content: center;
    gap: 10px;
    padding: 10px;
    .link {
      .qr {
        width: 320px;
        height: 320px;
      }
      .download {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        padding: 0 10px;
        .store {
          height: 40px;
          width: 130px;
          cursor: pointer;
        }
      }
    }
    .text {
      padding: 10px;
      h1 {
        font-size: 30px;
        line-height: normal;
      }
      p {
        font-size: 16px;
        color: #2c3782;
      }
    }
    @media only screen and (max-width: 774px) {
      .text {
        h1 {
          font-size: 25px;
        }
        p {
          font-size: 15px;
        }
      }
    }
    @media only screen and (max-width: 707px) {
      .text {
        h1 {
          font-size: 23px;
        }
        p {
          font-size: 14px;
        }
      }
    }
    @media only screen and (max-width: 680px) {
      .main {
        .section {
          flex-direction: column;
          align-items: center;
          height: 100%;
          .text {
            h1 {
              font-size: 21px;
              font-weight: 700 !important;
            }
            p {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .main {
    height: auto;
    .section {
      flex-direction: column;
      align-items: center;
      height: 100%;
      .text {
        h1 {
          font-size: 21px;
          font-weight: 700 !important;
          text-align: justify;
        }
        p {
          font-size: 13px;
        }
      }
    }
  }
}