@import "../../../../assets/style/mixins.less";

.container {
  border: 1px solid #0000000f;
  border-radius: 5px;
}

.block {
  border-bottom: 1px solid #0000000f;
  padding: 10px;
  height: 100%;

  &:last-child {
    border: none;
  }
}

.block-form-item {
  .block;
  padding-bottom: 0;
}

.wrapper {
  .container;
  padding: 10px;
  height: 100%;

  :global(.ant-tree-treenode-disabled .ant-tree-title) {
    color: #00000050;
  }
}

.wrapper-form-item {
  .wrapper;
  padding: 15px;
  padding-bottom: 0;
}

.mul-select :global(.ant-select-selection-item) * {
  color: #fff;
}

.dropdown-mul-select {
  :global(.ant-select-item-option-content) {
    color: @navy1;
  }
  :global(.ant-select-item-option-selected) * {
    color: #fff;
  }
}

.update-background{
  background-color: #f4f6fa;

}

.tree {
  :global(.ant-tree-title) {
    color: @navy1;
  }
  background-color: #f4f6fa;

}

.tree-node{
  display: block;
}

.iconAdd {
  position: absolute;
  bottom: 30px;
  right: 15px;

  @media screen and (max-width:1400px) {
    bottom: 12px;
  }
}

.title{
  font-weight: 700 !important;
  font-size: 20px !important;
}
.icon-title{
  color:#6576FF;
  transform:scale(1.5);
  margin: 0 12px;
}