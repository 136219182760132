@import url('../../../../../assets/style/mixins.less');

.drawer-title {
    :global(.ant-drawer-title) {
        // background-color: #fff;
    }
    :global(.ant-drawer-header) {
        background: #f3f6f9;
        padding-right: 15px;
    }
    :global(.ant-drawer-body) {
        overflow: hidden;
    }
}

.btn-tab-tt {
    width: 122px;
    // background: ;
    background-color: #F4F7FF !important;
    border-radius: 5px;
    span {
        color: #999;
    }
    border: none;
    height: 31px;
    &:global(.ant-btn-primary) {
        span {
            color: #fff;
        }
        background-color: @navy1 !important;
    }
}