@import "../../../assets/style/mixins.less";

.container {
  padding: 15px;

  .inner {
    background-color: #fff;
    border-radius: 8px;

    .topbar {
      padding: 10px 20px;
      border-bottom: 1px solid #ededed;
    }

    .main-wrap {
      padding: 20px;
      height: calc(100vh - 140px);
      overflow: auto;

      .main {
        display: grid;
        row-gap: 10px;
        column-gap: 10px;

        @media screen and (max-width: 2000px) {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        }

        @media screen and (max-width: 1800px) {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }

        @media screen and (max-width: 1600px) {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        @media screen and (max-width: 1400px) {
          grid-template-columns: 1fr 1fr 1fr;
        }

        @media screen and (max-width: 1200px) {
          grid-template-columns: 1fr 1fr;
        }

        .item {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          gap: 10px;
          padding: 15px;
          border: 0.5px solid #d3d3d3;
          border-radius: 8px;
          height: fit-content;

          img {
            width: 24px;
            object-fit: contain;
          }

          :global(.navy-txt) {
            flex: 1;
          }

          .download-icon {
            color: #999999;
            font-size: 18px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
