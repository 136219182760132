@import "../../../../../assets/style/mixins.less";

.container {
    :global(.ant-form-item){
        margin-bottom: 10px ;
    }
    .item_status {
        
        display: flex;
        align-items: center;
        justify-content:  center;
        padding: 10px;
        
        
    }
    .lable {
        color:  #2c3782;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
    }
}