@import "../../../../assets/style/mixins.less";

.state {
  display: flex;
  width: 100px;
  height: 27px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
}

.not-active-yet,
.completed {
  background: #ebf4ff;
  color: #4263eb;
}

.active {
  background: rgba(0, 216, 165, 0.1);
  color: #00d8a5;
}

.pending {
  background: rgba(255, 211, 124, 0.2);
  color: #ffd37c;
}

.expired-soon {
  color: #f34946;
  background: #fdebeb;
}

.expired {
  background: #f3f1f1;
  color: #999999;
  ;
}
