@import "../../../assets/style/mixins.less";

.container-layout {
  // height: 100vh;
  overflow: hidden !important;
}
.tabs {
  :global(.ant-tabs-nav) {
    background-color: #fff;
    padding-inline: 15px;
    height: @header-height;
    margin-bottom: 0;

  :global(.ant-table-header.ant-table-cell){
    padding-right: 16px;
  }


    button {
      min-width: 180px;
      text-transform: uppercase;
      color: #999;
      background-color: #f5f7fb;
      border-color: #f5f7fb;
      height: auto;
      padding: 8px;
      line-height: 1em;

      * {
        font-size: 11px;
        font-weight: 600 !important;
      }

      &.active {
        background-color: #3c49a3;
        border-color: #3c49a3;
        color: #fff;
      }
    }

    :global(.ant-tabs-ink-bar) {
      background-color: @orange1;
    }

    :global(.ant-tabs-nav-list) {
      gap: 15px;
    }

    :global(.ant-tabs-tab) {
      margin-left: 0;
      gap: 10px;
    }

    :global(.ant-table) {
      line-height: 0.5715;
    }

  }

  :global(.ant-tabs-content-holder) {
    height: calc(100vh - @header-height * 2);
    overflow: auto;
    // padding: 15px;
  }

  // style lại form item nhập thông tin
  :global(.ant-col.ant-form-item-label) {
    padding-bottom: 3px !important;
  }
  :global(.ant-form-item-explain-error){
    display: none !important;
  }

  :global(.ant-form-item) {
    margin-bottom: 3px !important;
  }

  :global(.ant-form-item-explain.ant-form-item-explain-connected) {
    display: none !important;
  }

}

.no-data-screen {
  width: 100%;
  height: calc(100vh - @header-height);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.header-row{
  width: 100%;
  background-color: @white;
  // border-bottom: 1px solid #f0f0f0;
  padding-right: 10px;
  .header-text {
    padding-left: 10px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.sider--header-container{
  height: 40px;
  border-bottom: 1px solid #f0f0f0;
  .plus-icon {
    font-size: 30px;
      margin-top: 3px;

      svg {
        color: @main-color;
        font-size: 22px;
      }
  }
}

.delete-icon {
  fill: #999999;
  width: 20px;
  height: 20px;
  &:hover {
    fill: #f34946;
  }
  cursor: pointer;
}

.add-drug {
  display: flex;
  // flex-grow:1;
  flex-direction: column;
  margin: 0px 20px 0 20px;
  background-color: @white;
  border-radius: 10px 10px 0 0;
  height: calc(100vh - 308px);
  overflow: hidden;

  .sider-them-thuoc {
    padding: 0px 0px 10px 0px;
    background-color: @white;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #f0f0f0;

    :global(.ant-select-disabled .ant-select-selector) {
      background-color : #F5F7FB !important;
    }
    :global(.ant-input-group-wrapper-status-error .ant-input-group-addon){
      border-color: transparent !important;
    }
    :global(.ant-form-item) {
      margin-bottom: 3px !important;
    }
    :global(.ant-layout-sider-children){
      width: 90%;
    }

  :global(.ant-form-item-label){
    padding-bottom: 0px !important;
  }

    .layout-btn-thuoc {
      margin: 10px 0 10px 0;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .btn-them-thuoc {
        width: 100%;
      }
    }
  }

  //style ds thuốc đã chọn
  .content-thuoc {
    // height: calc(100vh - 400px);
    // overflow: auto;
    .table-thuoc{
      padding: 10px 10px 0 0px;
      background-color:#F5F7FB;
      // margin-right: 22px;
      height: calc(100vh - 330px);
      :global(.ant-table-body) {
        height: calc(100vh - 444px);
      }
      td {
        height: 40px;
      }
      :global(.ant-select), :global(.ant-input-number) {
        width: 100%;
      }
      :global(.ant-input-number-group-addon) {
        background-color: #f5f7fb;
        font-weight: 600;
        max-width: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    background-color: @white;
  }

  .footer {
    background-color: #fff;
    // border-top: 1px solid #e0e0e0;
    padding: 0;

    .wrap-price {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 10px;

      .sub-price {
        display: flex;
        flex-direction: column;
      }
    }

    .wrap-btn-payment {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: right;
      height: @header-height;
      // gap: 15px;
      // padding-inline: 15px;

      .button-payment {
        width: 100%;
        min-width: 100%;
      }
    }
  }
}

.sider-dash {
  margin: 8px 0;
}

.elipsis-column {
  width: '100%';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-line-clamp: 3;
  // -webkit-box-orient: vertical;
}
.label-so-luong{
  font-weight: 700 !important;
  .label-so-luong-value{
    margin-left: 5px;
    color: @green1;
    font-size: 12px;
    font-weight: 700 !important;
  }
}
