@import "../../../../assets/style/mixins.less";

.green-tag {
  font-size: 14px;
  padding: 3px 10px;
  color: @green1 !important;
  background-color: @green2 !important;
  border-color: @green1 !important;
  font-weight: 600 !important;
  margin-top: -5px;
  margin-bottom: -5px;
}
.footerPrice {
  float: right;
  padding-right: 15px;
  font-size: 15px;
}
.red-tag {
  .green-tag;
  color: @red1 !important;
  background-color: @red2 !important;
  border-color: @red1 !important;
}
.boldTxt {
  font-weight: 1000 !important;
}
.click-txt {
  cursor: pointer;
  text-decoration: underline;
  color: @green1;
  font-weight: 600 !important;
}
.icon-delete-btn {
  fill: #999999;
  &:hover {
    fill: #f34946;
  }
}
.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.disable-icon-delete-btn {
  fill: #99999950;
}

.topbar-btn-row {
  margin-right: 10px !important;
  padding-right: 5px;
  border-right: 1px solid #f0f0f0;

  .topbar-btn {
    display: flex;
    align-items: center;
    div > span {
      background-color: transparent !important;
    }
    & > span {
      display: flex !important;
      align-items: center;
      & > button {
        border: none !important;
      }
    }
    & > button {
      border: none !important;
    }
    .btn{
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }
  }

  :global(.ant-space-item) {
    display: flex !important;
    align-items: center;
    :global(.ant-btn-link) {
      border: none;
      background: #fff;
      &:hover svg {
        fill: #6576ff;
      }
      &:hover {
        border-color: #6576ff;
      }
    }

    & > span {
      display: flex !important;
      align-items: center;
      &:hover {
        background-color: #f5f5f5;
      }
      &:hover svg {
        fill: #999999 !important;
      }

      & > button {
        color: rgba(0, 0, 0, 0.25);
        // border: none !important;
        background: #f5f5f5 !important;
      }
    }
  }
}

// .total-price-ctn {
//   margin-inline: 15px;
//   padding: 10px 15px;
//   padding-top: 25px;
//   border-radius: 10px;
//   text-align: end;
//   margin-bottom: 20px;
//   margin-top: -30px;
//   color: @navy1;
//   font-weight: 600 !important;
//   background-color: #fff;
//   box-shadow: 0 0 5px #00000015;

//   .total-price {
//     color: @green1;
//     font-weight: 600 !important;
//     font-size: 18px;
//   }
// }

.total-price-ctn {
  padding: 0px 15px;
  text-align: end;
  color: @navy1;
  font-weight: 600 !important;
  font-size: 14px !important;

  .total-price {
    color: @green1;
    font-weight: 600 !important;
    font-size: 18px !important;
  }
}

.popup {
  margin-top: 10px;
  min-width: 170px;

  :global(.ant-popover-inner-content) {
    padding: 0;
  }

  .wrapper {
    padding: 10px 0;
    border-bottom: 1px solid #f0f0f0;

    .label {
      color: #999;
      font-size: 12px;
      margin-top: 10px;
      padding: 0 25px;
    }

    .inner {
      padding: 0 25px;
      margin-top: 15px;
      margin-bottom: 5px;
    }

    .item {
      .inner;
      margin-top: 5px;
      margin-bottom: 0;
      padding-block: 10px;
      transition: all 0.1s ease-in-out;

      &:last-child {
        margin-top: 0;
      }

      svg {
        margin-right: 10px;
      }

      &.hover {
        cursor: pointer;

        &:hover {
          background-color: @navy1;

          .txt {
            color: #fff;
          }

          svg {
            filter: brightness(200%);
          }
        }
      }
    }
  }
}

.navy-txt {
  color: @navy1;
}

.blue-txt {
  color: @main-color;
}

.green-txt {
  color: @green1;
}

.dvcd-tag {
  color: @navy1 !important;
}

.Ds-wrapper {
  //background-color: #f5f5f5;
  :global(.ant-table-row-level-0) {
    & > td {
      // background-color: #f5f7fb;
    }
  }
  :global(.ant-table-wrapper) {
    border: none !important;
  }
}

.table-container {
  :global(.ant-empty) {
    padding-top: 10%;
    // min-height: calc(100vh - 360px) !important;
  }
}

.svg-fill-white {
  svg {
    path {
      fill: #fff;
    }
  }
}