.banner {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: center;
}

.container {
  padding: 2.5vmin;

  h1 {
    color: #0ec0f4;
    font-weight: bold !important;
    font-size: 7vmin;
  }

  .current-data {
    h1 {
      margin-bottom: 2vmin !important;
    }

    .item {
      background-color: #0ec0f4;
      color: #fff;
      font-size: 7.5vmin;
      padding: 1vmin 3vmin;
      border-radius: 1.25vmin;
      margin-bottom: 2.5vmin !important;

      * {
        font-size: 7.5vmin;
        font-weight: bold !important;
      }
    }
  }

  .waiting-data {
    h1 {
      margin-bottom: 1vmin !important;
    }

    .item {
      font-size: 6vmin;
      margin-bottom: 1.5vmin !important;
      color: #344b90;
      padding: 0vmin 2.5vmin;

      * {
        color: #344b90;
        font-size: 6vmin;
        font-weight: bold !important;
      }
    }
  }
}
