@import "../../../../assets/style/mixins.less";

.button {
  min-width: 100px;
  button {
  min-width: 100px;

  }
  // border-radius: 8px;
  //height: 20px;
}

.icon-edit-btn {
  fill: #999999;
  &:hover {
    fill: #2cb2a5;
  }
}

.icon-delete-btn {
  fill: #999999;
  &:hover {
    fill: #f34946;
  }
}

.disable-icon-delete-btn {
  fill: #ebebeb;
}

.icon-edit-btn-dis {
  fill: #ebebeb;
}

.print-btn-icon {
  * {
    transition: all 0.1s ease-in-out;
  }
  .icon {
    color: #999;
    font-size: 18px;
  }
  &:hover {
    background-color: @main-color;
    .icon {
      color: #fff;
    }
  }

  &.disabled-print {
    background-color: #fff !important;
    .icon {
      color: #99999950 !important;
    }
  }
}

