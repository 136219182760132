@import "../../../../../assets/style/mixins.less";

.container {
    // padding: 15px;

    .header {
        padding: 20px 26px;
    }

    .title {
        font-size: 22px;
        font-weight: 700 !important;
    }
    height : calc(100vh - 55px);
    background-color: #F5F7FB;

    .table {
        background-color: #fff;
        // border : 1px solid #E4E4E4;
        border-radius: 10px;
        margin: 15px 20px;
        // box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);


            :global(.ant-table-thead) {
                th {
                    background-color: #fff !important;
                }
            }
    }

    .form-infomation {
        padding: 11px 25px;
        background-color: #fff;
    }
    // :global(.ant-table-title, .ant-table-footer) {
    //     padding-left: 10px !important;
    //     padding-right: 10px !important;
    // }
    :global(.ant-table-cell) {
        padding: 5px !important;
        color: #2C3782;
    }
    :global(.ant-table-thead) {
        :global(.ant-table-cell) {
          color: #2C3782;
          background-color: #F5F7FB;
        }
    }
    :global(.ant-table-cell-with-append) {
        padding-right: 0px !important;
    }
    :global(.ant-table-row-expand-icon .ant-table-row-expand-icon-expanded) {
        border: none;
        &::after {
          display: none !important
        }
    }

    .icon {
        // color: colors.grayColor,
        border: none !important;
        &::after {
          display: none !important
        }
    }

    .child-row .ant-table-cell-with-append {
        .ant-table-row-indent .ant-table-row-expand-icon {
          display: none,
        }
      }
      .child-parent  {
        .ant-table-cell.ant-table-cell-with-append{
          padding-right: 0px !important
        }
      }

      .form__top--action {
        display: flex;
        flex-direction: row;
        height: 43px;
        span {
          font-size: 12px !important;
        }
        font-size: 12px !important;
      
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        background: #fff;
        align-items: center;
      
        .patient-title {
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          padding: 10px 12px;
          border-right: 1px solid #d7d7d7;
      
          &:last-child {
            border-right: none;
          }
        }
      }
}
.button-file {
    color: #6576FF;
    background-color: #E5EEFD;
    border: none;

}

.container-file {
    margin: 15px;
    border: 1px solid #E4E4E4;
    border-radius: 10px;
    .header{
        border-bottom: 1px solid #E4E4E4;
        padding: 15px;
    }
    .list-file{
        padding: 15px;
    }
    .list{
        padding: 10px;
        // background-color: #F5F7FB;
        border-radius: 10px;
        margin-top: 10px;
        min-height: 50px;
    }
    .item-file {

        // height: 55px;
        width: 100%;
        padding: 10px;
        // aspect-ratio: 1 / 1;
        background-color: #F5F7FB;
        // background-color: #FFF;
        border-radius: 10px;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        .icon{
            width: 20px;
            height: 20px;
            cursor: pointer;
            transform: translateY(3px);
        }
    }
}
