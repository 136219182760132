.container {
  // padding : 10px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #f5f7fb;
  margin-top: 8px;
  height: 32px;

  .box-icon {
    border-radius: 5px;
    // background-color: #FFFFFF;
    width: 25px ;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2px;
  }
  .title {
    color: #2C3782;
    font-size: 18px;
    font-weight: 700 !important;
  }
}
