@padding: 10vmin;

.container {
  display: flex;
  flex-wrap: wrap;

  .room {
    width: 50%;
    height: 50vh;
    border: 0.1vmin solid #333;
    overflow: hidden;

    .title {
      background-color: #2a4bb4;
      padding-inline: @padding;
      height: 15vmin;
      display: flex;
      align-items: center;

      h1 {
        font-size: 6vmin;
        color: #fff;
        margin-block: 0px;
      }
    }

    .content {
      padding-inline: @padding;

      p {
        color: #344b90;
        font-size: 5.5vmin;
        margin-block: 0px;
        margin-top: 2vmin;

        span {
          font-weight: bold !important;
          font-size: 5.5vmin;
        }
      }
    }
  }
}
