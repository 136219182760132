.container {
    margin-top: 10px;
}

.modal-add-khai-thac {
    background-color: red;
    border-radius: 15px;
    .text {
        font-weight: 600 !important;
        color : #2c3782 !important
    }
    :global(.ant-modal-body) {
        padding: 15px;
    }
}