@import "../../../../../assets/style/mixins.less";

.container {
  background-color: #fff;
  border-radius: 5px 0px 0px 5px;
  height: calc(100vh - 195px);
  overflow: auto;
  border-right: 1px solid #ededed;

  :global(.ant-form-item-explain) {
    display: none;
  }

  :global(.ant-form-item) {
    margin-bottom: 10px;
  }

  label {
    font-size: 12px !important;
    font-weight: bold !important;
  }

  :global(.ant-form-item-label) {
    padding-bottom: 3px;
  }

  .form {
    display: flex;
    flex-direction: column;
    height: 100%;

    .main {
      flex: 1;
      overflow: auto;
      padding: 10px 15px;
      padding-bottom: 0px;
      border-top: 1px solid #ededed;
    }

    .btn-wrapper,
    .title {
      padding: 10px 15px;
      background-color: #fff;
    }

    .title {
      height: 50px;

      h4 {
        margin-bottom: 0px;
      }

      svg {
        fill: @blue2;
        font-size: 18px;
        margin-bottom: -2px;
      }
    }
  }

  :global(.ant-input-number-group-wrapper) {
    width: 100%;
  }

  :global(.ant-input-number) {
    border-color: #f5f7fb;
    width: 100%;
  }

  .input-chia-lam {
    :global(.ant-input-group-addon) {
      background-color: #f5f7fb;
      padding-inline: 3px !important;
    }
  }

  .config-kedon {
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 6px;

    border-radius: 10px;
    background-color: @blue3;
    width: 200px;
    height: 100px;

    h6 {
      font-weight: 700 !important;
    }

    :global(.ant-radio-wrapper) {
      color: @navy1;
      &:first-child {
        margin-bottom: 6px;
      }
    }
  }
}

.search-bar {
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #ededed;
}

.popup-ctn {
  background-color: #f5f7fb;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 0 10px #00000025;
}

.label-sl-toi-da {
  font-size: 12px !important;
  font-weight: bold !important;
  // margin-bottom: -2px;
}

.suffix-lan {
  background-color: #fff;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 5px;
}

.label-input-moi-ngay {
  display: block;
  margin-bottom: 3px !important;
  position: relative;

  // &::before {
  //   content: "*";
  //   font-size: 14px;
  //   color: #ff4d4f;
  //   display: inline-block;
  //   line-height: 1;
  //   font-family: SimSun, sans-serif;
  //   margin-right: 4px;
  // }
}

.input-moi-ngay {
  background-color: #f5f7fb;
  border-radius: 5px;
  // margin-bottom: 10px;

  :global(.ant-select-selector) {
    background-color: #fff !important;
    height: 28px !important;
  }

  * {
    font-size: 12px !important;
  }

  input {
    height: 28px !important;
  }

  :global(.ant-select-selection-item),
  :global(.ant-select-selection-placeholder) {
    line-height: 28px !important;
  }
}

.input-cach-dung input {
  color: @navy1;
}

.popup-cach-dung {
  :global(.ant-select-item-option-content) {
    color: @navy1;
  }
}
