@import "../../../../../assets/style/mixins.less";

.container {
  :global(.ant-select-selection-item) {
    color: @navy1;
  }

  :global(.ant-select-selection-item) * {
    color: #fff;
  }

  :global(.ant-select-selector) {
    color: @navy1;
  }
}

.search {
  padding: 5px 10px;

  .input {
    border-radius: 50px;
    // height: 36px;
  }

  .icon {
    margin-right: 5px;
    margin-bottom: 3px;
  }
}

.add {
  background-color: #f5f7fb;
  display: flex;
  align-items: center;
  gap: 10px;
  color: @main-color;
  padding: 10px;
  cursor: pointer;

  .icon {
    margin-bottom: 3px;
  }
}

.search,
.add {
  .icon {
    color: @main-color;
    font-size: 20px;
    margin-top: 3px;
  }
}

.dropdown {
  padding: 2px 0 0 0 !important;

  :global(.ant-select-item-option-content) {
    color: @navy1;
  }

  :global(.ant-select-item-option-selected) * {
    color: #fff;
  }

  :global(.ant-select-tree-switcher) {
    width: 10px;
  }

  :global(.ant-select-tree-title) {
    color: @navy1;
  }
  :global(.ant-select-tree-treenode) {
    padding-left: 10px;
  }
  :global(.ant-select-tree-node-content-wrapper) {
    padding-left: 7px;
  }
}

.container-mul {
  :global(.ant-select-selection-item) * {
    color: #fff;
  }
}

.table-head {
  padding: 5px 10px;
  color: @navy1 !important;
  border-bottom: 1px solid #ededed;
}

.select-dropdown-table {
  :global(.ant-col) {
    color: @navy1;
  }
}
