@import "../../../assets/style/mixins.less";

.container {
  padding: 15px;
  :global(.ant-modal-body) {
    padding: 0 !important;
  }
  .content {
  padding-block: 18px;
  background-color: white;
  // height: calc(100vh - @header-height - 40px) !important;
  border-radius: 8px;
  .pdf {
    padding-top: 20px;
    height: calc(100vh - @header-height - 80px) !important;
  }

  .topBar {
    display: flex;
    justify-content: space-between;
    // border-bottom:  1px solid #EDEDED;
    padding-inline: 18px;
    padding-bottom: 18px;
  }
  .leftTop {
    display: flex;
  }
  .line {
    border: 1px solid #ededed;
    width: 100%;
  }

  .title {
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #2c3782 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
  }
  .product {
    margin: auto;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    // border-radius: 20px;

    iframe {
      // left: 0;
      // top: 0;
      // height: 100%;
      // width: 100%;
      // position: absolute;
      width: 100%;
    }
  }

  :global(.ant-segmented-item-selected) {
    background-color: #6576ff;
    color: white;
  }
  :global(.ant-segmented-item) {
    transition: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  .PdfBox {
    background: #ffffff;
    border: 0.5px solid #d3d3d3;
    border-radius: 8px;
    // height: 53px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #2c3782;
    font-size: 13px;
    font-weight: 600;
    padding: 15px;
    //  text-align: center;
    cursor: pointer;
  }

  iframe {
    width: 100%;
    border-radius: 20px;
    aspect-ratio: 2 / 1.15;
    border: 0.5px solid #d3d3d3;
  }
  }
}
