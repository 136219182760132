@import "../../../../assets/style/mixins.less";

.hide-error-txt {
  :global(.ant-col.ant-form-item-label) {
    padding-bottom: 5px !important;
    @media only screen and (max-width:1200px){
      padding-bottom: 2px !important;
    }
  }

  :global(.ant-form-item) {
    margin-bottom: 5px !important;
    @media only screen and (max-width:1200px){
      margin-bottom: 2px !important;
    }
  }

  :global(.ant-form-item-explain.ant-form-item-explain-connected) {
    display: none !important;
  }
}

.container {
  padding :10px 15px;
  :global(.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    background: #f5f7fb;
    cursor: text;
  }
}

.wrapper {

  &.last-item {
    border: none;
  }

  &:last-child {
    border: none;
  }

  h4 {
    font-weight: 600 !important;
  }

  &.pad-bot {
    padding-bottom: 15px;

    h4 {
      margin-bottom: 0 !important;
    }

    .search-bar svg {
      color: @main-color;
      font-size: 20px;
      margin-right: 5px;
    }
  }

  .inner {
    padding: 15px;
    // border: 1px solid #e2e2e2;
    border-radius: 10px;
    margin-bottom: 15px;

    &.top {
      border-bottom-width: 0px;
      border-radius: 10px 10px 0 0;
    }

    &.bottom {
      border-top-width: 0px;
      border-radius: 0 0 10px 10px;
    }

    &.switches {
      padding: 0;

      .switch {
        // background-color: red;
        padding: 20px;
        padding-bottom: 0;
        border-top: 1px dashed #e1e1e1;

        &.first {
          border-right: 1px solid #e1e1e1;
        }

        &.last {
          border-left: 1px solid #e1e1e1;
        }
      }
    }

    :global(.ant-col.ant-form-item-label) {
      padding-bottom: 5px !important;
    }

    :global(.ant-form-item) {
      margin-bottom: 5px !important;
    }

    :global(.ant-form-item-explain.ant-form-item-explain-connected) {
      display: none !important;
    }
  }

  label,
  .label {
    color: @navy1;

    &.label-custom {
      margin-top: 4px;
    }
  }

  .read-only {
    background-color: #fff;
    border-color: #e5e5e5;

    input {
      background-color: #fff;
    }
  }

  :global(.ant-table-thead) {
    box-shadow: 0 0 4px #00000010;

    :global(.ant-table-cell) {
      border: none !important;
      background-color: #f5f7fb;
      padding-block: 10px !important;

      &::before {
        width: 0px !important;
      }
    }
  }
}

.btn-groups {
  display: flex;
  background-color: #f5f7fb;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 15px;

  .btn {
    color: @navy1;
    padding: 5px 10px;
    border-radius: 5px;
    min-width: 120px;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &.active {
      color: #fff;
      background-color: @main-color;
    }
  }
}

.add-new-btn {
  * {
    color: @main-color;
  }

  margin-bottom: 15px;
  cursor: pointer;
  width: fit-content;

  svg {
    font-size: 18px;
    margin-bottom: -2px;
  }
}

.delete-btn {
  margin-top: 10px;
  margin-left: 5px;
  font-size: 18px;
}

.checkbox {
  color: @navy1 !important;
  margin-right: 20px;
}

.step-bar {
  padding: 5px 15px 0 15px;
  border-bottom: 1px dashed #e1e1e1;

  svg {
    margin-bottom: -10px;
  }
}

.label {
  color: @navy1;
}
.button {
  min-width: 120px;
  margin: 0 10px;
}
.pdf {
  overflow: auto;
}
.wrapper_pdf {
  display: flex;
  justify-content: center;
  //align-items: center;
  background-color: #e2e2e2;
  padding: 20px;
  height: 100vh;
}
.button-history {
  //background-color: red;
  display: flex;
  //height: 100%;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #e2e2e2;
}
.groupButton {
  background-color: #f5f7fb;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 20px;
}
.menu-select {
  width: 230px;
  background-color: #fff;
  //padding:10px;
  border-radius: 10px;
  box-shadow: 0 0 10px #00000025;
  padding: 10px;
}
.row-select {
  margin-top: 10px;
  cursor: pointer;
  padding: 5px 20px;
  border-radius: 5px;
  //margin-bottom: 10px;
  &:hover {
    background-color: #6576ff;
    .row-select-text {
      color: #fff !important;
    }
    .row-select-room {
      color: #fff !important;
    }
    .row-select-room-title {
      color: #fff !important;
    }
  }
}
.row-select-title {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 10px 20px;
}
.menu-select-room {
  background-color: #fff;
  //padding:10px;
  border-radius: 10px;
  box-shadow: 0 0 10px #00000025;
  width: 450px;
}
.row-select-room {
  color: #2c3782;
  display: flex;
  justify-content: center;
}
.row-select-room-title {
  color: #2c3782;
}

.dropdown-custom {
  * {
    cursor: pointer;
    color: #cecece !important;
  }

  input {
    color: @green1 !important;
  }
  svg {
    color: #cecece !important;
  }

  &.blue input {
    color: @navy1 !important;
  }
}

.total-price {
  background-color: #ffffff;
  position: sticky;
  bottom: 0;
  padding: 15px 15px;
  // margin-top: -1px !important;
  border: 1px solid #00000010;
}
.tab-ctn {
  //overflow: visible;
  //height: calc(100vh - @header-height);
  //padding-left: ;

  // :global(.ant-tabs-content.ant-tabs-content-top) {
  //   //height: 100%;
  //   //background-color: #F5F7FB;
  // }

  :global(.ant-tabs-nav) {
    margin-bottom: 0;
    //position: sticky;
    //top: @header-height;
    background-color: #fff;
    box-shadow: 0 0 10px #00000010;
    z-index: 1;
    padding-left: 25px;
  }

  :global(.ant-tabs-nav-wrap) {
    //width: 100%;
    border-bottom: 1px solid #e7e7e7;
  }

  // :global(.ant-tabs-nav-list) {
  //   //width: 100%;
  // }

  :global(.ant-tabs-tab) {
    width: 100%;
    // flex: 20%;
    justify-content: center;
    text-transform: uppercase;
    //white-space: normal;
    color: #777;
    padding: 15px;
    font-size: 12px;

    * {
      font-weight: 700 !important;
    }

    &:global(.ant-tabs-tab-active) * {
      color: @navy1;
    }
  }

  :global(.ant-tabs-content-holder) {
    // height: calc(100vh - @header-height - 40px);
    overflow: auto;
    padding-bottom: 15px;
  }

  :global(.ant-tabs-ink-bar.ant-tabs-ink-bar-animated) {
    background-color: @orange1 !important;
  }

  :global(.ant-tabs-nav-operations) {
    display: none !important;
  }
}
.icon-reload {
  svg {
    width: 20px;
    // height: 20px;
    margin-top: 5px;
  }
}

.container {
  overflow: hidden;
}

.firstRow {
  :global(.ant-select-selector), :global(.ant-input) {
    border: 1px solid @main-color !important;
  }
  :global(.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input) {
    border-color: #ff4d4f !important;
  }
}
