.wrapper {
  background-color: #fff;
  padding: 15px;
  //border-radius: 5px;
  // margin-block: 10px;
  border-top-left-radius : 5px;
  border-top-right-radius : 5px;

  :global(.ant-form-item) {
    margin-bottom: 5px !important;
  }

  :global(.ant-input) {
    background-color: #F5F7FB;
    border : none;
  }
}
.nguoi-than {
  border-top: 1px dashed #E2E2E2;
  border-radius: 0 0 5px 5px;
}
