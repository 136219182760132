@import "../../../../../../assets/style/mixins.less";
.container-ticket {
    padding: 2rem;
  }
  
  .text-ellipsis {
    display: block;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .bold {
    font-weight: bold !important;
  
    font-size: 14px;
    line-height: 18px;
  
    color: @navy1;
  }
  .star{
    margin-top: -10px;
    font-size: 20px;
    :global(.anticon .anticon-star){
    font-size: 30px;
    }
  }
  .row-side{
    padding: 0 12px;
  
    .row-item{
      color: @navy1;
      margin-bottom: 8px;
    }
  }
  .radio-group{
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    justify-content: space-evenly;
  }