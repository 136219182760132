.container_import {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex: 1;
    height: calc(65vh);
    :global(.tui-image-editor-header-buttons) {
        display: none !important;
    }

    :global(.tui-image-editor-header-logo) {
        display: none !important;
    }
    
    .upload-container {
        width: 850px;
        height: calc(60vh);
        .current-image {
            width: 100%;
            height: calc(60vh);
            object-fit: cover;
            
        }
    }

    .list-images{
        width: 300px;
        margin-left: 10px;
        height: calc(60vh);
        
    }
    .default-image{
        width: 141px;
        background-color: #fff;
        aspect-ratio: 1/1;
        border: 1px dashed #D9D9D9;
        border-radius: 6px;
        display: flex;
        justify-items: center;
        align-items: center;
        cursor: pointer;
        
        .image-current {
            width: 100%;
            aspect-ratio: 1/1;
            object-fit: cover;
            border-radius: 6px;
        }
        .delete-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: #D9D9D9;
            border-radius: 10px;
            padding: 0 6px;
            cursor: pointer;
        }
    }


    :global(.ant-upload-list-item-info) {
        display: none !important;
    }

    :global(.ant-upload-list-item-list-type-text) {
        display: none;
    }
}