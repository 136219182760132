

.modal-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    height: 540px;


    // height: calc(100vh - 400px);
    :global(.tui-image-editor-header-buttons) {
        display: none !important;
    }

    :global(.tui-image-editor-header-logo) {
        display: none !important;
    }
    
    .video-container{
        //background-color: red;
        width: 870px;
        height: 535px;
        margin-left: 10px;
        video, img{
            width: 100%;
        }
    }
    .preview-imagess{
       width: 850px;
       height: 500px;
       img {
        width: 850px;
        height: 500px;
       }
    }

    .preview-image{
        width: 300px;
        margin-right: 15px;
        
        
        .item-img-preview {
            padding: 0px !important;
            border: 1px dashed #999;
            border-radius: 10px;
            position: relative;
            margin-left: 5px !important;
        }

        .icon-delete {
            position: absolute;
            background-color: #999;
            opacity: 0.7;
            top: 7px;
            right: 7px;
            padding: 0px 6px;
            border-radius: 50%;
            cursor: pointer;
        }    
    }

}

.text-timer{
    margin-top: 5px !important;
    span {
        font-weight: 700 !important;
        font-size: 12px;
        line-height: 15px;
        color: #2C3782 !important;
        
    }
}


