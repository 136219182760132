.footer {
    background-color: #fff;
    display: flex;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    right: 0;
    left: 310px;
    z-index: 5;
    align-items: center;
    justify-content: space-between;
    // height: @header-height;
    padding-inline: 10px;
    height: auto;
    padding-block: 10px;
    border-top: 1px solid #f0f0f0;
    @media only screen and (max-width:1200px){
      button {
        padding: 2px 5px;
        span {
          font-size: 12px;
        }
      }
    }
}

.note-container {
    margin-top: 12px;
    span {
      font-weight: bold;
      font-size: 15px;
      color: #2c3782;
    }
    .note {
      background-color: #FFF;
     
    }
  }