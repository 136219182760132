@import "../../../../assets/style/mixins.less";

.wrapper {
  padding: 15px 25px 0 25px;
  border-bottom: 1px dashed #e1e1e1;

  &:last-child {
    border: none;
  }

  h4 {
    font-weight: 600 !important;
  }

  &.pad-bot {
    padding-bottom: 15px;

    h4 {
      margin-bottom: 0 !important;
    }

    .search-bar svg {
      color: @main-color;
      font-size: 20px;
      margin-right: 5px;
    }
  }

  .inner {
    padding: 20px;
    padding-bottom: 5px;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    margin-bottom: 25px;
    //background-color: #F5F7FB;

    &.top {
      border-bottom-width: 0px;
      border-radius: 10px 10px 0 0;
    }

    &.bottom {
      border-top-width: 0px;
      border-radius: 0 0 10px 10px;
    }

    &.switches {
      padding: 0;

      .switch {
        // background-color: red;
        padding: 20px;
        padding-bottom: 0;
        border-top: 1px dashed #e1e1e1;

        &.first {
          border-right: 1px solid #e1e1e1;
        }

        &.last {
          border-left: 1px solid #e1e1e1;
        }
      }
    }

    .checkBox {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  label {
    color: @navy1;

    &.label-custom {
      margin-top: 4px;
    }
  }

  .read-only {
    background-color: #fff;
    border-color: #e5e5e5;

    input {
      background-color: #fff;
    }
  }

  :global(.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before) {
    display: none;
  }

  :global(.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after) {
    display: inline-block;
    margin-inline-start: 4px;
    color: #ff4d4f;
    font-size: 10px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
    visibility: unset !important;
  }
}

.add-new-btn {
  * {
    color: @main-color;
  }

  margin-bottom: 15px;
  cursor: pointer;
  width: fit-content;

  svg {
    font-size: 18px;
    margin-bottom: -2px;
  }
}

.delete-btn {
  margin-top: 10px;
  margin-left: 5px;
  font-size: 18px;
}

.checkbox {
  color: @navy1 !important;
  margin-right: 20px;
}

.step-bar {
  padding: 5px 15px 0 15px;
  border-bottom: 1px dashed #e1e1e1;

  svg {
    margin-bottom: -10px;
  }
}

.label {
  color: @navy1;
}

.button {
  min-width: 120px;
  margin: 0 10px;
}

.pdf {
  overflow: auto;
}

.wrapper_pdf {
  display: flex;
  justify-content: center;
  //align-items: center;
  background-color: #e2e2e2;
  padding: 20px;
  height: 100vh;
}

.button-history {
  //background-color: red;
  display: flex;
  //height: 100%;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #e2e2e2;
}

.groupButton {
  background-color: #f5f7fb;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 20px;
}

.menu-select {
  width: 230px;
  background-color: #fff;
  //padding:10px;
  border-radius: 10px;
  box-shadow: 0 0 10px #00000025;
  padding-bottom: 20px;
}

.row-select {
  margin-top: 10px;
  cursor: pointer;
  padding: 5px 20px;
  border-radius: 5px;

  //margin-bottom: 10px;
  &:hover {
    background-color: #6576ff;

    .row-select-text {
      color: #fff !important;
    }

    .row-select-room {
      color: #fff !important;
    }

    .row-select-room-title {
      color: #fff !important;
    }
  }
}

.row-select-title {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 10px 20px;
}

.menu-select-room {
  background-color: #fff;
  //padding:10px;
  border-radius: 10px;
  box-shadow: 0 0 10px #00000025;
  width: 450px;
}

.row-select-room {
  color: #2c3782;
  display: flex;
  justify-content: center;
}

.row-select-room-title {
  color: #2c3782;
}

.input-num {
  border-color: #f5f7fb;
}


.drawer {
  .header {
    background-color: #fff;
  }
  :global(.ant-drawer-body) {
    background-color: #F5F7FB;
    padding: 20px;
  }
  .info {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 15px 15px;
    margin-bottom: 20px;
    :global(.ant-form-item) {
      margin: 0;
    }
  }
}