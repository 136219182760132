@import "../../../../../assets/style/mixins.less";
.container {
  margin: 1rem;
  :-webkit-scrollbar {
    visibility: hidden !important;
  }
  .align-padd{
    padding: 1rem;
  }

  .wrapper {
    overflow: hidden !important;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    width: 100%;
    


    .form-item {
      display: block;
      margin: 0;
    }
    
    .loai-thuoc {
      display: flex;
      width: 100%;
      margin-top: 1rem;
      border-top: 1px dashed #ccc;
    }

    .form-item-vertical {
      display: flex;
      flex-direction: inherit;
      margin: 0;
      padding: 0.5rem;
      text-align: right !important;
      span {
        color: @navy1;
      }

    }
    .border-right {
      border-right: 1px solid #ccc;
    }
    .border-bottom{
      border-bottom: 1px dashed #ccc;
    }
  }
}

.background-title{
  background-color: #F3F6F9;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 1rem;
  color: @navy1;
}

.button-add{
  padding: 0.2rem 2.5rem 1rem 2.5rem;
}
.icon {
  font-size: 30px;
  margin-top: 3px;

  svg {
    color: @main-color;
  }
}
.modal-add-container{
:global(.ant-modal-body){
  padding: 0 0 2rem 0 !important;

}
}

.detail-ticket{
  justify-content: end;
  margin-right:1.2rem ;
}

.dropdown-container{
  box-shadow: #8352520f 0px 2px 8px;
  background-color: #fff;
  border-radius: 8px;
  max-height: 400px;

  .drop-btn{
    max-width: 80px;
    border: none;
    padding: 4px 6px;
    cursor: pointer;
    &.primary{
      background-color: @main-color;
    }
    &.green{
      background-color: @green1;
    }
  }
  .green-btn {
    background-color: @green1 !important;
    border-color: @green1 !important;
  }
  
}
.text-title {
  color:  @navy1;
  font-size: 13px;
  font-weight: 700 !important;
}

.footer-group {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  .footer-btn {
    width: 120px;
    height: 32px;
  }
}