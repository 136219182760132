.container {
  margin: 0 20px;
  padding: 20px 15px;
  background: rgba(245, 247, 251, 1);
  border-radius: 15px;
  color: rgba(44, 55, 130, 1);
  .title {
    text-align: center;
    line-height: 17.5px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .note {
    font-weight: 500 !important;
    margin-bottom: 7px;
    font-size: 14px;
  }
  .group {
    margin-left: 30px;
  }
}