.sider {
  height: calc(100vh - 60px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.03);
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
  }

  :global(.ant-form-item) {
    padding: 12px;
    margin-bottom: 0;
    label {
      font-weight: 700 !important;
    }
  }

  :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    background-color: #f5f7fb;
  }

  :global(.ant-divider-horizontal) {
    margin: 5px 0 0;
    border-color: #E0E0E0;
    border-width: 1.5px 0 0;
  }

  .footerBtn {
    padding-block: 13px;

    button {
      width: 105px;
      height: 33px;
      font-size: 13px;
    }
  }

  :global(.ant-select-multiple) {
    :global(.ant-select-selection-item) {
      background: #6576ff;
      color: #FFFFFF;

      svg {
        color: #FFFFFF;
      }
    }

    :global(.ant-select-selection-item-content) {
      line-height: 23.5px;
    }
  }
}

.topbar {
  background-color: #FFFFFF !important;
}

.drawer {
  :global(.ant-drawer-close) {
    display: none;
  }

  :global(.ant-drawer-header-title) {
    padding: 20px 30px 16px;
    height: 67px;
    h2 {
      font-size: 22px;
    }
  }

  .spaceDiv {
    width: 100%;
    justify-content: space-between;
    :global(.ant-input-affix-wrapper) {
      padding: 0 11px;
    }
    .title {
      display: flex;
      align-items: center;
      gap: 15px;
    }
    :global(.ant-checkbox) + span {
      color: #2c3782;
    }
  }

  :global(.ant-drawer-body) {
    padding: 12px 15px 0 15px;
  }

  :global(.ant-collapse) {
    background-color: transparent;

    :global(.ant-collapse-header) {
      align-items: center;

      :global(.ant-collapse-arrow) {
        font-size: 14px;
        margin: 4px 7px 0 5px;
      }

      :global(.ant-collapse-header-text) {
        font-size: 18px;
        font-style: normal;
        font-weight: 700 !important;
      }
    }
  }

  :global(.ant-collapse > .ant-collapse-item) {
    border-radius: 10px;
    background: #F4F7FF;
    border-bottom: none;
    margin-bottom: 15px;

    .wrapper {
      border-radius: 10px;
      background: #FFF;
      padding: 12px;
    }
  }

  :global(.ant-drawer-footer) {
    padding: 16px 24px;

    :global(.ant-btn) {
      min-width: 124px;
    }
  }

  :global(.ant-input-number) {
    width: 100%;
  }

  :global(.ant-input) {
    height: 32px;
  }

  :global(.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    background: #f5f7fb;
    cursor: default;

    &:hover {
      border-color: #8fa0ff;
      border-right-width: 1px;
    }
  }

  :global(.ant-form-item) {
    margin-bottom: 8px;
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  :global(.ant-btn-link) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    svg {
      width: 20px;
      height: 20px;
    }
    .print {
      color: #999999;
      &:hover {
        color: fuchsia;
      }
    }
    .reload {
      path {
        stroke: #999999;
      }
      &:hover {
        path {
          stroke: blue;
        }
      }
    }
    .money {
      path {
        fill: #999999;
      }
      &:hover {
        path {
          fill: green;
        }
      }
    }
    .delete:hover {
      path {
        stroke: red;
      }
    }
  }
  :global(.ant-btn-link[disabled]) {
    opacity: 0.5;
  }
}