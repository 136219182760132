@import ".././../../../assets/style/mixins.less";

.popup-ctn {
  background-color: #fff;
  box-shadow: 0 0 10px #00000025;
  border-radius: 5px;

  .input {
    padding: 15px;
  }
}

.table {
  :global(.ant-table-cell-row-hover) {
    // color: #fff !important;
    cursor: pointer;
  }

  :global(.ant-table-cell) {
    background-color: #fff;
    color: @navy1;
    padding-block: 10px;
  }

  :global(.ant-table-thead) {
    box-shadow: 0 0 4px #00000010;

    :global(.ant-table-cell) {
      border: none !important;
      background-color: #f9f9f9;

      &::before {
        width: 0px !important;
      }
    }
  }
}
