.container {
  padding-top: 15px;
  padding-bottom: 5px;

  .total-amount {
    margin-top: 10px;

    * {
      font-size: 14px;
    }
  }

  :global(.ant-select), :global(.ant-input-number) {
    width: 100%;
  }
  :global(.ant-input-number-group-addon) {
    background-color: #f5f7fb;
    max-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .important {
    font-weight: 700 !important;
    span {
      font-size: 14px;
      color: rgba(243, 73, 70, 1);
      font-weight: 700 !important;
    }
  }

  .table {
    :global(.ant-table-cell-fix-left) {
      left: 0 !important;
    }
    :global(.ant-input-number-group-addon) {
      font-weight: 600;
    }
    .med {
      position: relative;
      .txt {
        position: absolute;
        left: 0;
        bottom: -22px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
      }
    }
  }
  
}