@import "../../../../assets/style/mixins.less";

.container {
  :global(.ant-tree-switcher.ant-tree-switcher-noop) {
    display: none;
  }
  :global(.ant-tree-title) {
    color: @navy1;
  }
  .tree {
    :global(.ant-tree-title) {
      color: #2c3782;
    }
    :global(.ant-tree-node-selected) {
      background-color: #fff !important;
    }
    .disabled {
      span {
        color: rgba(0, 0, 0, 0.25) !important;
        cursor: not-allowed !important;
      }
      :global(.ant-tree-checkbox-inner) {
        background-color: #f5f5f5;
        border-color: #d9d9d9 !important;
        &::after {
          border-color: rgba(0, 0, 0, 0.25) !important;
        }
      }
      :global(.ant-tree-node-content-wrapper):hover {
        background-color: transparent;
      }
      :global(.ant-tree-checkbox-checked)::after {
        border: 1px solid #d9d9d9;
      }
    }
  } 
}
