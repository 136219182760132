@import "../../../../assets/style/mixins.less";
.header-kham-benh {
  padding: 10px 25px;
  background-color: #ffffff;
  display: flex;

  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 16px;
    font-weight: 700;
    color: #2c3782;
  }

  .header_ion {
    align-items:center;
    display:flex;
    gap:10px;
    border:1px solid #6576FF;
    padding: 6px;
    border-radius: 5px ;
    color: #6576FF;
    cursor: pointer;

    svg {
      path {
        fill: #6576FF !important;
      }
    }
  }
}
.layout {
  // height: calc(100vh - @header-height * 2);
  display: flex !important;
  flex-direction: column !important;
  height: 100%;
  .spin {
    height: 100%;
    :global(.ant-spin-container) {
      height: 100%;
    }
  }
  .content {
    // overflow: auto;
    padding: 0px !important;
    flex: auto !important;
    height: calc(100% - 40px);

    .container {
      background-color: #f5f7fb;
      border-radius: 5px;
      padding: 0 15px;
      height: 100%;

      .layout-container {
        background-color: #fff;
        margin-top: 10px;
        height: 100%;
      }
      .left-thongTin {
        background-color: #fff;
        // height: calc(100vh - @header-height * 2 - 120px);
        overflow: auto;
        .style-scroll-bar-hide(8px,8px,#fff);
        border-right: 1px solid #e0e0e0;
        padding: 10px;
        .button {
          background-color: #f4f6fa;
          color: #2c3782;
          min-width: 130px;
          max-width: 180px;
          height: 28px;
          border: none;
          margin-right: 10px;
        }
        .button-active {
          .button;
          background-color: #6576ff;
          color: #fff;
        }
      }
      .right-chiso {
        //width: 300px;
        overflow: auto;
        background-color: #fff;
      }

      .kham-lai {
        background-color: #fff;
        position: sticky !important;
        bottom: -15px;
        padding: 0;

        .form-bottom {
          margin-top: 0;
          border-top: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0;
          padding-block: 10px;
          margin-left: 0px !important ;
          margin-right: 0px !important ;
          :global(.ant-input),
          :global(.ant-input-affix-wrapper),
          :global(.ant-select-selector),
          :global(.ant-input-number) {
            background-color: #fff;
            border-color: #f0f0f0;
          }

          .blue-txt,
          .blue-txt * {
            color: @navy1;
          }

          .icon-calendar {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    :global(.ant-form-item) {
      margin-bottom: 8px;
    }

    label {
      color: @navy1;
    }

    .main-txt {
      color: @navy1;
    }
    .sub-txt {
      color: #aaafcf;
      font-size: 12px;
    }
  }

  .footer {
    height: @header-height;
    margin-bottom: -15px;
    padding: 15px;
    padding-inline: 15px;
    display: flex;
    align-items: center;
    background-color: #fff;
    // box-shadow: 0 0 5px #00000025;
    z-index: 1;
    justify-content: space-between;

    * {
      margin: 0;
    }

    position: sticky !important;
    bottom: -15px !important;
  }
}

.require-mark {
  color: @red1;
  margin-left: 5px;
  font-size: 20px;
  line-height: 0em;
}
