@import "../../../../assets/style/mixins.less";

.comtainer {


}

.title-container {
  border-top: 1px solid #E9E9E9;
  border-bottom: 1px solid #E9E9E9;
  padding: 10px 15px;
  .title{
    color: #2C3782;
    font-size: 12px;
    font-weight: 700 !important;
  }
}

.chiso-container  {
  background-color: #FFF;
  :global(.ant-input-affix-wrapper) {
    padding: 0 !important;
  }
  :global(.ant-input-affix-wrapper) {
    border : 1px solid #E8E8E8 !important;
  }
  :global(.ant-input-prefix) {
    background-color: #FFF;
    width: 70% !important;
    padding-left: 20px !important;
    font-size: 20px !important;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 1px solid #E8E8E8;
    p {
      color : @navy1 !important;
    }

  }
  :global(.ant-input){
    height: 35px !important;
    text-align: right;
    padding: 5px 10px !important;
  }

  :global(.ant-form-item-explain-error) {
    //display: none;
  }
}

.tien-su-ban-than {
  padding: 15px;
  width: 100%;
  background-color: #FFF;
  .text{
    color: #2C3782;
    font-weight: 700 !important;
    font-size: 12px;
  }

  :global(.ant-checkbox) {
    span {
      font-weight: 600 !important;
    }
  }
  :global(.ant-checkbox-disabled + span) {
    color: #2C3782 !important;
  }
  :global(.ant-radio-disabled + span) {
    color: #2C3782 !important;
  }
  :global(.ant-input-disabled) {
    color: #2C3782 !important;
    background-color: #F5F7FB;
  }
}

.bmi-wrapper {
  border: 1px solid #e9e9e9;
  height: 40px;
  border-radius: 6px;
  padding-left: 24px !important;
  h4 {
    line-height: 40px;
    display: inline-block;
    font-size: 15px;
    &:nth-child(2) {
      margin-left: 4px;
    }
  }
}