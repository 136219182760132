@import "../../../../../assets/style/mixins.less";

:global(.ant-modal-mask) {
  // z-index: 1051;
}
:global(.ant-modal-wrap) {
  // z-index: 1051;
}
.modal {
  :global(.ant-modal-header) {
    background-color: #f3f6f9;
  }
  :global(.ant-modal-content) {
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  :global(.ant-modal-body) {
    padding: 10px 22px;
  }

  .modal-header {
    svg {
      color: #fff;
    }

    .modal-icon {
      font-size: 30px;
      margin-right: 10px;

      svg {
        color: @main-color;
      }
    }
  }
  .btn {
    width: 124px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .red-btn {
    background-color: #ed6b69;
    border-color: #ed6b69;
    margin-right: 10px;
  }
}
