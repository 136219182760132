.container{
   padding: 18px;
  .box{
    margin: auto;
    background-color: white;
    padding-top: 39px;
    padding-bottom: 21px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    text-align: center;

    .line {
      margin-left: 25px;
      margin-right: 25px;
      border-bottom: 1px solid #F0F0F0;
      margin-bottom: 20px;
    }
  }
}