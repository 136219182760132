.wrapper {
  padding: 12px 12px 20px;
  background: #f5f7fb;
  height: 100%;
  margin: 15px;
  border-radius: 10px;

  .table {
    :global(.ant-table-row-expand-icon) {
      border: none;
      &::after {
        content:none !important;
      }
    }
    :global(.ant-table-row-level-0) {
      td {
        font-weight: 700 !important;
      }
    }
    :global(.ant-table-row-level-1) {
      :global(.ant-checkbox-wrapper) {
        display: none !important;
      }
    }
  }

  .reason {
    align-items: center;
    margin-top: 22px;
    padding-left: 10px;
    .input {
      background: white !important;
    }
  }
}

.footer {
  position: sticky;
  bottom: 0;
  background: white;
}