@import "../../../../assets/style/mixins.less";

.wrapper {
  padding: 15px 25px 0 25px;

  h4 {
    font-size: 18px;
  }

  &:last-child {
    border: none;
    padding-bottom: 15px;
  }

  .inner {
    padding: 20px;
    padding-bottom: 0;
    border: 1px solid #e2e2e2;
    border-radius: 10px;

    &.top {
      border-bottom-width: 0px;
      border-radius: 10px 10px 0 0;
      margin-bottom: 0;
    }

    &.bottom {
      border-top-width: 0px;
      border-radius: 0 0 10px 10px;
    }

    &.switches {
      padding: 0;

      .switch {
        padding: 5px 20px;
        border-top: 1px dashed #e1e1e1;

        &.first {
          border-right: 1px solid #e1e1e1;
        }

        &.last {
          border-left: 1px solid #e1e1e1;
        }
      }
    }
  }

  label {
    color: @navy1;

    &.label-custom {
      margin-top: 4px;
    }
  }

  :global(.ant-input-group-addon) {
    background-color: #f5f7fb;
    border-left: 1px solid #e4e6f8;
    color: #aaafcf;
  }
}

.input-num {
  border-color: #f5f7fb;
}

.form-thuoc {
  padding: 0 !important;

  .first-part {
    padding: 15px;
    padding-bottom: 0px;
  }

  .row-thuoc {
    border-top: 0.5px dashed #e1e1e1;
    padding: 10px 15px;
  }

  .colums-thuoc {
    border-right: 1px dashed #e1e1e1;

    .lable-thuoc {
      font-weight: 700 !important;
      color: #2c3782 !important;
      font-size: 14px !important;
    }

    span {
      color: #2c3782;
      font-size: 14px !important;
    }

    &.no-border-right {
      border-right: none;
    }
  }

  :global(.ant-input-number) {
    border-color: #f0f0f0;

    &:global(.ant-form-item-has-error) {
      border-color: #ff4d4f !important;
    }
  }

  :global(.ant-form-item-has-error) {
    :global(.ant-input-number) {
      border-color: #ff4d4f;
    }
  }
  // TAB

}

.formThuoc{
 
  background-color: #F5F7FB;
  height: calc(100vh - 50px);
 
  // :global(.ant-tabs-nav){
  //   background-color: #FFFFFF !important;
  // }
  .tab-ctn {
  :global(.ant-tabs-nav) {
    margin: 0;
    background-color: #FFF !important;
    padding-bottom: 15px;
    &::before {
      display: none;
    }
  }
  :global(.ant-tabs-tabpane){
    background-color: #F5F7FB !important;
    padding-top: 10px !important;
  }
  :global(.ant-tabs-nav-wrap) {
    padding: 15px 25px 0 25px;
  }
  :global(.ant-tabs-ink-bar) {
    display: none;
  }
  :global(.ant-tabs-tab) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    min-width: 160px;
    margin: 0 5px;
    padding: 0;
    background-color: #f5f7fb;
    border-radius: 4px;
    color: #999999;
  }
  :global(.ant-tabs-tab-active) {
    background: #3C49A3;
    color: #ffffff !important;

    :global(.ant-tabs-tab-btn) {
        color: #ffffff
    }
  }
  .input-search{
    width: 250px;
    border-radius: 15px;
    border: 1px solid #EEF4FF !important;
    margin-right: 20px !important;
    margin-top: 20px;
    svg {
      width:  30px;
    }
  }
}}

.time-picker {
  background-color: #f5f7fb;
}

.form-kho-thuoc {
  :global(.ant-form-item){
      margin-bottom: 10px ;
  }
  .item_status {
      
      display: flex;
      align-items: center;
      justify-content:  center;
      padding: 10px;
      
  }
  .lable {
      color:  #2c3782;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
  }
}