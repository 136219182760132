@import ".././../../../assets/style/mixins.less";

.input {
  background-color: #fff;

  input {
    background-color: #fff;
  }

  .icon * {
    color: @main-color;
  }
  .clear-btn{
    border-radius: 4px;
    background: #2C3782;
    width: 42px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      font-size: 10px;
    }
  }
}

.table {
  width: 800px;

  :global(.ant-table-cell-row-hover) {
    // color: #fff !important;
    cursor: pointer;
  }

  :global(.ant-table-cell) {
    background-color: #fff;
    color: @navy1;
    padding-block: 10px;
  }

  :global(.ant-table-thead) {
    box-shadow: 0 0 4px #00000010;

    :global(.ant-table-cell) {
      border: none !important;
      background-color: #f9f9f9;

      &::before {
        width: 0px !important;
      }
    }
  }
}

.dropdown {
  background-color: #fff;
  box-shadow: 0 0 10px #00000025;
  border-radius: 10px;
  margin-top: 10px;

  .head {
    padding: 10px 15px;
  }
}
.total-num {
  background-color: @main-color;
  color: #fff;
  padding-inline: 5px;
  border-radius: 5px;
  height: 28px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.auto-complete {
  min-width: 350px !important;
  color:@navy1 !important;

  :global(.ant-select-item) {
    color:@navy1 !important;
  }
}

.input-search{
  border-color: #6576ff !important;
  height: 34px;
}