@import "../../../../../assets/style/mixins.less";

.tab-ctn{
  opacity: 1;

  :global {
    .ant-tabs-nav {
      display: none !important;
    }
  }
}

.so_diem_tc_wrap{
  background-color: #F5F7FB;
  border: 1px solid #F5F7FB;
  border-radius: 5px;
  overflow: hidden;
  width: 250px;
  display: flex;
  align-items: center;
  margin-right: 60px;
  height: 40px;
}

.so_diem_tc{
  padding: 10px;
  font-size: 13px;
  font-weight: 600;
  background-color: #fff;
  margin-right: 8px;
}

.form-stop-bang{
  margin: 24px 40px;
  border:  1px solid #E6E6E6;
  border-radius: 10px;
  padding: 12px 24px;
  margin-bottom: 0;

}

  
.value-option{
  color: #2C3782;
  font-size: 14px;
  font-weight: 600;
}

.form-stop-bang_noti_wrap{
  padding: 24px 40px;
  display: flex;

  .form-stop-bang_dot{
    color: @red3;
    margin-right: 4px;
  }
  .form-stop-bang_noti{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: @navy1;
  }
}

.mask{
  -webkit-filter: drop-shadow(1px 1px 5px rgb(19, 2, 248))
  drop-shadow(0 0 0 rgb(19, 2, 248));
filter: drop-shadow(1px 1px 5px rgb(19, 2, 248)) 
drop-shadow(0 0 0 rgb(19, 2, 248));
}

.info{
  display: flex;
  gap: 4px;
  padding-bottom: 10px;
  color: #6576FF;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.groupButton{
  // display: flex;
  // gap: 12 !important;
  label{
    padding: 5px 21px !important;
    border-radius: 5px !important;
    display: flex;
    align-items: center;
    border: 1px solid #d9d9d9;
  }
  :global {
    .ant-radio-button-wrapper:not(:first-child)::before {
      display: none !important;
    }
  }
}




.mask-group {
  // background-color: red !important;
  :global {
    .ant-checkbox{
      display: none !important;
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0 !important;
  }
  }
}

.marginLeft{
  padding-left: 12px !important;
}
.marginLeft31{
  padding-left: 20px !important;
}

.form-eyes{
  display: flex;
  gap: 25px;
  padding-left: 12px;

  :global{
    .ant-form-item {
      width: 200px;
    }

    .ant-input-number-group-addon{
      background-color: #ffffff !important;
      padding-right: 34px !important;
    }
  }
}

.chiso-container {
  :global(.ant-input-affix-wrapper) {
    padding: 0 !important;
  }
  :global(.ant-input-affix-wrapper) {
    border: 1px solid #e8e8e8 !important;
  }
  :global(.ant-input-prefix) {
    background-color: #fff;
    width: 190px !important;
    padding-left: 20px !important;
    font-size: 20px !important;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 1px solid #e8e8e8;
    p {
      color: @navy1 !important;
    }
  }
  :global(.ant-input) {
    height: 35px !important;
    text-align: right;
    padding: 5px 10px !important;
  }

  :global(.ant-form-item-explain-error) {
    //display: none;
  }
}

.chiso-container-2 {
  :global(.ant-input-affix-wrapper) {
    background-color: #fff;
    padding: 12px;
  }
  :global(.ant-input-prefix) {
    background-color: #fff;
    width: 190px !important;
    font-size: 20px !important;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    p {
      color: @navy1 !important;
    }
  }
  :global(.ant-input) {
    height: 35px !important;
    text-align: right;
    padding: 5px 10px !important;
  }
}

.form-container {
  margin-top: 15px;
  // height: calc(100vh - @header-height * 2 - 175px);
  overflow: auto;
  padding-inline: 15px;
  :global(.ant-form-item-label) {
    padding-bottom: 5px !important;
  }
}

.noti-phan-quyen {
  color: red;
  margin: auto;
  text-align: center;
  transition: all 1s;
}

.noti-phan-quyen-hidden {
  visibility: hidden;
  transition: all 1s;
}

.rating-items {
  width: 40px;
  height: 40px;
  text-align: center;
  align-content: center;
  flex: 1;
  border-radius: 50%;
  flex-direction: column;
  position: relative;
  :global(.ant-radio) {
    display: block;
  }
  
  :global(.ant-radio-checked) {
    :global(.ant-radio-inner) {
      display: block;
      background-color: @main-color;
      width: 40px !important;
      height: 40px !important;
    }
  }
  :global(.ant-radio-inner) {
    display: block;
    border: none;
    background-color: #E5E9FB;
    width: 40px !important;
    height: 40px !important;
    &::after {
      display: none;
    }
  }

  .rating-value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-30%);
    font-size: 14px;
  }
  &:global(.ant-radio-wrapper-checked) {
    span {
      color: #fff !important;
    }
  }
}