@import "../../../../../assets/style/mixins.less";

.container {
    padding: 12px 24px;

    :global(.ant-table-head) {
        background-color: #F5F7FB !important;
    }
}

.title-tien {
    margin: 12px 0;
    color: @navy1;
    font-size: 13px;
}

.form-header {
    background-color: #fff !important;
}

.footer-drawer {
    border-top: 1px solid #e4e4e4;
}

.table-ht {
    :global(.ant-table-row-indent) {
        display: none;
    }
    :global(.ant-table-row-expand-icon-spaced) {
        display: none;
    }
}

.header {
    height: unset;
    padding: 10px 15px 15px;
    :global(.ant-form-item) {
        margin: 0;
    }
}
