@import "../../../assets/style/mixins.less";

.sub-header {
  position: fixed;
  top: 60px;
  z-index: 1;
  background-color: white !important;
  width: calc(100% - 60px - 90px);
  .date-select {
    width: 160px;
  }
  :global(.ant-picker-input){
    input {
      text-align: center;
    }
  }
}

.item {
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  color: @navy1;
  position: relative;
  padding-left: 25px;
  line-height: 1.5em;
}

.wrapper {
  padding: 15px 25px 0 25px;
  border-bottom: 1px dashed #e1e1e1;

  &:last-child {
    border: none;
  }

  h4 {
    font-weight: 600 !important;
  }

  &.pad-bot {
    padding-bottom: 15px;

    h4 {
      margin-bottom: 0 !important;
    }

    .search-bar svg {
      color: @main-color;
      font-size: 20px;
      margin-right: 5px;
    }
  }

  .inner {
    padding: 20px;
    padding-bottom: 5px;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    margin-bottom: 25px;
    //background-color: #F5F7FB;

    &.top {
      border-bottom-width: 0px;
      border-radius: 10px 10px 0 0;
    }

    &.bottom {
      border-top-width: 0px;
      border-radius: 0 0 10px 10px;
    }

    &.switches {
      padding: 0;

      .switch {
        padding: 20px;
        padding-bottom: 0;
        border-top: 1px dashed #e1e1e1;

        &.first {
          border-right: 1px solid #e1e1e1;
        }

        &.last {
          border-left: 1px solid #e1e1e1;
        }
      }
    }
  }

  label {
    color: @navy1;

    &.label-custom {
      margin-top: 4px;
    }
  }

  .read-only {
    background-color: #fff;
    border-color: #e5e5e5;

    input {
      background-color: #fff;
    }
  }
}

.container {
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 15px;
  @media screen and (max-width: 2000px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  @media screen and (max-width: 1800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @media screen and (max-width: 1600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .name_container{
    margin-bottom: 20px;
  }

  .item-wrapper {
    border-radius: 10px;
    background-color: white;
    padding: 24px;
    cursor: pointer;

    h2 {
      color: #2c3782;
      font-weight: bold !important;
      margin: 0;
      .txt-ellipsis();
      padding: 10px 0;
      font-size: 18px;
    }

    li {
      padding: 5px 0px !important;
    }

    .num_count {
      color: #2c3782;
      font-size: 26px;
      font-weight: bold !important;
    }

    .num_total {
      color: #73789e;
    }

    .inner-box {
      padding: 3px 8px;
      border: 1px solid #e2e2e2;
      border-radius: 8px;
      font-size: 13px;
      min-width: 70px;
      text-align: center;

      &.green {
        border-color: #2cb2a5;
        color: #2cb2a5;
        background-color: #f3fffe;
      }

      &.gold {
        border-color: #f6a447;
        color: #f6a447;
        background-color: #fff6ec;
      }

      &.red {
        border-color: #f34946;
        color: #f34946;
        background-color: #fff2f2;
      }
    }

    .receipt-code {
      font-size: 14px;
      color: #2cb2a5;
      font-weight: bold !important;
    }
    .inner {
      padding: 10px;
      border: 1px solid #f3f8fc;
      color: #2c3782;
      border-radius: 10px;
      margin-bottom: 15px;
      background-color: #f3f8fc;
    }
  }
}

.icon-trash{
  transform: scale(1.4);
  margin-top: 4px;
  margin-inline: 10px;
  //margin: 2px 0 0 0px;
  // path:nth-of-type(even),
  // path:nth-of-type(odd) {
  //   // stroke: red !important;
  // }
  fill: #999999;
  &:hover {
    path{
      fill: #f34946;
    }
  }

}
.icon-trash-disabled {
  path {
  fill: #ccc;
  }
}

.icon-edit{
  // transform: scale(1.4);
  // margin-top: 3px;
  // margin-inline: 10px;
  // //margin: 2px 0 0 0px;
  // // path:nth-of-type(even),
  // // path:nth-of-type(odd) {
  // //   // stroke: red !important;
  // // }
  margin-left: 10px;
  fill: #999999;
  &:hover {
    path{
      fill: #2cb2a5;
    }
  }
  
}

.popup {
  padding: 0 !important;
  min-width: 170px;

  :global(.ant-popover-inner-content) {
    padding: 0;
  }
  .wrapper {
    padding: 10px 0;
    border-bottom: 1px solid #f0f0f0;

    .label {
      color: #999;
      font-size: 12px;
      margin-top: 5px;
      padding: 0 25px;
    }

    .inner {
      padding: 0 25px;
      margin-top: 15px;
      margin-bottom: 5px;
    }

    .item {
      .inner;
      margin-top: 5px;
      margin-bottom: 0;
      padding-block: 10px;
      transition: all 0.1s ease-in-out;
      cursor: pointer;

      .txt {
        color: @navy1;
      }

      &:last-child {
        margin-top: 0;
      }

      svg {
        margin-right: 10px;
      }

      &:hover {
        background-color: @navy1;

        .txt {
          color: #fff;
        }

        svg {
          filter: brightness(200%);
        }
      }
    }
  }
}

.styledPagination{
  position: absolute;
  bottom: 0;
  left: 60px;
  width: calc(100% - 60px - 90px)!important;
  background-color: #f5f7fb;
}
