@import "../../../../../assets/style/mixins.less";

.wrapper {
  border: 1px solid #e2e2e2;
  border-radius: 5px 5px 0 0;
  padding: 15px;
  padding-bottom: 0;
  margin-top: 5px;

  // style lại form item nhập thông tin
  :global(.ant-col.ant-form-item-label) {
    padding-bottom: 3px !important;
  }

  :global(.ant-form-item) {
    margin-bottom: 3px !important;
  }

  :global(.ant-form-item-explain.ant-form-item-explain-connected) {
    display: none !important;
  }
}

.no-data-screen {
  width: 100%;
  height: calc(100vh - @header-height);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.wrap-sider {
  height: calc(100vh - @header-height - 5px);
  border-right: 1px solid #f0f0f0;
  overflow: auto;
  background-color: @white;

  .user-profile {
    display: flex;
    flex-direction: column;
    background-color: @white;
    border-radius: 10px 10px 10px 10px;
    padding: 0px 15px 0px 15px;
    height: 100%;

    .layout-btn-thuoc {
      margin: 10px 0 10px 0;
      display: flex;
      flex-direction: row;
      justify-content: left;

      .btn-them-thuoc {
        width: 100%
      }
    }
  }
}

.header-text {
  height: 40px;
  display: flex;
  padding: 5px 15px 5px 15px;
  background-color: @white;
}

.add-drug {

  .sider-them-thuoc {
    padding: 0px 10px 10px 10px;
    background-color: @white;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #f0f0f0;
    

    .layout-btn-thuoc {
      margin: 10px 0 10px 0;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .btn-them-thuoc {
        width: 100%;
      }
    }
    
  }

  //style ds thuốc đã chọn
  .content-thuoc {
    height: calc(100vh - 230px);
    overflow: auto;

    .table-container{
      padding: 15px 15px 5px 15px;
    }
  }

  .content-thuoc-with-template {
    height: calc(100vh - 265px);
    overflow: auto;

    .table-container{
      padding: 15px 15px 5px 15px;
    }
  }

  .footer {
    background-color: @white;
    padding: 0;


    .sub-price {
      margin: 5px 15px 5px 0;
    }
    
    .note-container{
      padding: 5px 15px 10px 15px;
      background-color: #F5F7FB;

      :global(.ant-input){
        background-color: @white;
      }
      .label{
        color:#2C3782;
        font-weight: 700 !important;
      }
    }

    .template-container{
      padding: 0px 15px 5px 15px;
      background-color: #F5F7FB;
      color:#2C3782;
      :global(.ant-input){
        background-color: @white;
      }
      :global(.ant-form-item){
        margin-bottom: 5px;
      }
      .label{
        color:#2C3782;
        font-weight: 700 !important;
      }
    }

    .wrap-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: @header-height;
      gap: 15px;
    }
  }
}

.sider-dash {
  margin: 8px 0;
}

.input-num {
  border: 0 ;
  width: 100%;
}

.form-container{
  height: calc(100vh - @header-height - 60px);
  padding-top: 10px;
  overflow:auto;
  width: 100%;

  
  // style lại form item nhập thông tin
  :global(.ant-col.ant-form-item-label) {
    padding-bottom: 3px !important;
  }

  :global(.ant-form-item) {
    margin-bottom:10px !important;
  }

}
.delete-icon {
  fill: #999999;
  width: 20px;
  height: 20px;
  margin-left: 14px;
  svg{
    font-size: 16px;
  }
  &:hover {
    fill: #f34946;
  }
}

.input-template{
  width: 270px;
}

.label-so-luong{
  font-weight: 700 !important;
  .label-so-luong-value{
    margin-left: 5px;
    color: @green1;
    font-size: 12px;
    font-weight: 700 !important;
  }
}