@import "../../../assets/style/mixins.less";

.spin{
  // height: calc(100% - 60px) !important;
}

.wrapper {
  padding: 0px 0px;
  border-bottom: 1px solid #e7e7e7;
  background-color: #fff;

  ;
  @media only screen and (max-width:1200px){
    label {
      font-size: 12px !important;
    }
  }
  

  &.last-item {
    border: none;
  }

  &:last-child {
    border: none;
  }

  h4 {
    font-weight: 600 !important;
  }

  &.pad-bot {
    padding-bottom: 15px;

    h4 {
      margin-bottom: 0 !important;
    }

    .search-bar svg {
      color: @main-color;
      font-size: 20px;
      margin-right: 5px;
    }
  }

  .inner {
    padding: 15px;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    margin-bottom: 15px;

    &.top {
      border-bottom-width: 0px;
      border-radius: 10px 10px 0 0;
    }

    &.bottom {
      border-top-width: 0px;
      border-radius: 0 0 10px 10px;
    }

    &.switches {
      padding: 0;

      .switch {
        // background-color: red;
        padding: 20px;
        padding-bottom: 0;
        border-top: 1px dashed #e1e1e1;

        &.first {
          border-right: 1px solid #e1e1e1;
        }

        &.last {
          border-left: 1px solid #e1e1e1;
        }
      }
    }

    :global(.ant-col.ant-form-item-label) {
      padding-bottom: 5px !important;
    @media only screen and (max-width:1200px){
      padding-bottom: 2px !important;
    }
    }

    :global(.ant-form-item) {
      margin-bottom: 5px !important;
      @media only screen and (max-width:1200px){
        margin-bottom: 2px !important;
      }

    }

    :global(.ant-form-item-explain.ant-form-item-explain-connected) {
      display: none !important;
    }
  }

  label,
  .label {
    color: @navy1;

    &.label-custom {
      margin-top: 4px;
    }
  }

  .read-only {
    background-color: #fff;
    border-color: #e5e5e5;

    input {
      background-color: #fff;
    }
  }

  :global(.ant-table-thead) {
    box-shadow: 0 0 4px #00000010;

    :global(.ant-table-cell) {
      border: none !important;
      // background-color: #f5f7fb;
      padding-block: 10px !important;

      &::before {
        width: 0px !important;
      }
    }
  }
  
  // :global(.ant-input-number),:global(.ant-input-affix-wrapper) {
  //   border-color: #E5E9FB;
  // }
}

.btn-groups {
  display: flex;
  background-color: #f5f7fb;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 15px;

  .btn {
    color: @navy1;
    padding: 5px 10px;
    border-radius: 5px;
    min-width: 120px;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &.active {
      color: #fff;
      background-color: @main-color;
    }
  }
}

.add-new-btn {
  * {
    color: @main-color;
  }

  margin-bottom: 15px;
  cursor: pointer;
  width: fit-content;

  svg {
    font-size: 18px;
    margin-bottom: -2px;
  }
}

.delete-btn {
  margin-top: 10px;
  margin-left: 5px;
  font-size: 18px;
}

.checkbox {
  color: @navy1 !important;
  margin-right: 20px;
}

.step-bar {
  padding: 5px 15px 0 15px;
  border-bottom: 1px dashed #e1e1e1;

  svg {
    margin-bottom: -10px;
  }
}

.label {
  color: @navy1;
}
.button {
  min-width: 120px;
  margin: 0 10px;
}
.pdf {
  overflow: auto;
}
.wrapper_pdf {
  display: flex;
  justify-content: center;
  //align-items: center;
  background-color: #e2e2e2;
  padding: 20px;
  height: 100vh;
}
.button-history {
  //background-color: red;
  display: flex;
  //height: 100%;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #e2e2e2;
}
.groupButton {
  background-color: #f5f7fb;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 20px;
}
.menu-select {
  width: 230px;
  background-color: #fff;
  //padding:10px;
  border-radius: 10px;
  box-shadow: 0 0 10px #00000025;
  padding: 10px;
}
.row-select {
  margin-top: 10px;
  cursor: pointer;
  padding: 5px 20px;
  border-radius: 5px;
  //margin-bottom: 10px;
  // &:hover {
  //   background-color: #6576ff;
  //   .row-select-text {
  //     color: #fff !important;
  //   }
  //   .row-select-room {
  //     color: #fff !important;
  //   }
  //   .row-select-room-title {
  //     color: #fff !important;
  //   }
  // }
}
.row-select-title {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 10px 20px;
}
.menu-select-room {
  background-color: #fff;
  //padding:10px;
  border-radius: 10px;
  box-shadow: 0 0 10px #00000025;
  width: 450px;
}
.row-select-room {
  color: #2c3782;
  display: flex;
  justify-content: center;
}
.row-select-room-title {
  color: #2c3782;
}

.dropdown-custom {
  * {
    cursor: pointer;
    color: #cecece !important;
  }

  input {
    color: @green1 !important;
  }
  svg {
    color: #cecece !important;
  }

  &.blue input {
    color: @navy1 !important;
  }
}

.total-price {
  background-color: #ffffff;
  position: sticky;
  bottom: 0;
  padding: 15px 15px;
  // margin-top: -1px !important;
  border: 1px solid #00000010;
}
.tab-ctn {
  background-color: #fefefe !important;
  //overflow: visible;
  //height: calc(100vh - @header-height);
  //padding-left: ;

  // :global(.ant-tabs-content.ant-tabs-content-top) {
  //   //height: 100%;
  //   //background-color: #F5F7FB;
  // }

  :global(.ant-tabs-tab) {
    padding: 10px !important;


  }

  :global(.ant-tabs-nav) {
    margin-bottom: -7px;
    // padding-left: 10px !important;
    //position: sticky;
    //top: @header-height;
    height: 45px;
    background-color: transparent;
    z-index: 1;
    padding: 0 19px;

    &::before {
      display: none;
    }
  }



  // :global(.ant-tabs-nav-list) {
  //   //width: 100%;
  // }

  :global(.ant-tabs-tab) {
    width: 100%;
    // flex: 20%;
    justify-content: center;
    text-transform: uppercase;
    //white-space: normal;
    color: #777;
    padding: 15px;
    font-size: 12px;

    * {
      font-weight: 700 !important;
    }

    &:global(.ant-tabs-tab-active) * {
      color: @navy1;
    }
  }

  :global(.ant-tabs-content-holder) {
    // height: calc(100vh - @header-height - 40px);
    // overflow: auto;
  }

  :global(.ant-tabs-ink-bar.ant-tabs-ink-bar-animated) {
    background-color: @orange1 !important;
  }

  :global(.ant-tabs-nav-operations) {
    display: none !important;
  }
}
.icon-reload {
  svg {
    width: 20px;
    height: 20px;
    margin-top: 5px;
  }
}

.sider-thong-tin-benh-nhan {
  padding: 10px;
  background-color: @white;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #f0f0f0;
  height: calc(100vh - 101px);
  overflow: auto;
}

.footer {
  background-color: #fff;
  display: flex;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  right: 0;
  left: 310px;
  z-index: 5;
  align-items: center;
  justify-content: space-between;
  // height: @header-height;
  padding-inline: 10px;
  height: auto;
  padding-block: 10px;
  border-top: 1px solid #f0f0f0;
  @media only screen and (max-width:1200px){
    button {
      padding: 2px 5px;
      span {
        font-size: 12px;
      }
    }
  }
}

  @media only screen and (max-width:1200px) {
    :global(.ant-checkbox-wrapper) {
      :global(.navy-txt) {
        font-size: 12px;
      } 
    }
  }

.header {
  padding: 6px 10px;

  h1 {
    font-size: 18px;
  }
}
.contentHeader {
  // border: 1px solid rgb(235, 237, 240);
  background-color: #fff !important;
  padding-block: 0px;
  padding-inline: 17px;

  :global(.ant-page-header-content) {
      padding: 6px;  
  }

  :global(.ant-page-header-heading-title) {
    font-size: 18px;
  }
  @media only screen and (max-width:1200px) {
    h2 {
      font-size: 18px;
    }
  }
  //  width: 100%;
  //  height: fit-content;
  //  padding: 20px;
}
.visibility {
  visibility: hidden;
}

.note-container {
  span {
    font-weight: bold;
    font-size: 15px;
    color: #2c3782;
  }
  .note {
    background-color: #FFF;
    height: 125px;
  }
}

.exam-info {
  display: inline-block;
  padding: 10px;
  color:@navy1;
  .exam-info-title {
    font-weight: 700 !important;
    text-transform: uppercase;
    text-shadow: 0 0 0.25px currentcolor;
  }
  .exam-info-line {
    width: 100%;
    height: 3px;
    background-color: #F6A447;
    border: 1px black !important;
  }
}

.radio-tabs {
  display: flex;
  gap: 12px;
  :global(.radio-tabs-btn) {

    &:before {
      content: none;
    }
    background-color: rgba(255, 255, 255, 1);
    color: rgba(44, 55, 130, 1);
    border-radius: 5px !important;
    border: none !important;

    text-transform: uppercase;

    line-height: 36px;
    height: 36px;
    min-width: 200px;
    text-align: center !important;

    span {
      font-weight: 700 !important;
    }

    &:global(.ant-radio-button-wrapper-checked) {
      background-color: #3C49A3;
      color: #fff;
    }
    // &:hover {
    //   opacity: 0.7;
    // }
  }
}

.wrapper-formDrawer {
  :global(.ant-drawer-header) {
    background: #f3f6f9;
    padding-right: 15px;
  }
  :global(.ant-drawer-body) {
    &::-webkit-scrollbar {
      width: 7px !important;
      height: 7px !important;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgb(186, 192, 198);
      border-radius: 10px;
    }
  }
}

.collapse-ttbn {
  background-color: #F5F6FA !important;
  border-radius: 12px;
  :global(.ant-collapse-header) {
    :global(.ant-collapse-header-text) {
      text-transform:uppercase;
      font-weight: 700 !important;
      margin: 2px 0 -2px;
    }
  }
  :global(.ant-collapse-content-box) {
    padding:0px !important;
    padding-bottom: 4px !important;
  }
  .panel-input {
    height: 25px;
    width: 130px;
    background: #fff;
  }
}

.expand-icon {
  background-color: #F5F6FA;
  padding-block: 4px;
  &::before {
    border-top-color: transparent !important;
  }

  &::after {
    border-top-color: transparent !important;
  }
}

.wrapper-ttbn {
  background-color: #fff;
  border-radius: 10px;
  margin-inline: 12px !important;

  :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector ) {
    border: 1px solid #E5E9FB
  }
  :global(.ant-form-item-control-input-content ) {
    input {
      border: 1px solid #E5E9FB
    }
  }
}

.wrapper-tabs {
  margin-inline-start: 12px;
  padding-inline-end: 8px;
}

.wrapper-collapse {
  margin: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.txt-ellipsis {
  display: inline-block;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; /* text-overflow: clip; */
  max-width: 120px;
}

.debtLimit {
  margin: 0 20px;
  padding: 15px;
  background: rgba(245, 247, 251, 1);
  border-radius: 15px;
  color: rgba(44, 55, 130, 1);
  p {
    text-align: center;
    line-height: 17.5px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  span {
    font-weight: 700 !important;
    font-size: 14px;
  }
  :global(.ant-space) {
    margin-left: 25px;
    margin-bottom: 10px;
  }
}

.drawer {
  :global(.ant-drawer-header) {
    background: #f5f7fb;
    padding-right: 20px;
  }
  :global(.ant-drawer-body) {
    padding: 0 15px;
  }
  :global(.ant-collapse) {
    margin-top: 20px;
    background-color: transparent;
    :global(.ant-collapse-header) {
      align-items: center;
      :global(.ant-collapse-arrow) {
        font-size: 14px;
        margin: 4px 7px 0 5px;
      }
      :global(.ant-collapse-header-text) {
        font-size: 18px;
        font-style: normal;
        font-weight: 700 !important;
        text-transform: uppercase;
      }
    }
  }
  :global(.ant-collapse > .ant-collapse-item) {
    border-radius: 10px;
    background: #F4F7FF;
    border-bottom: none;
    margin-bottom: 15px;
    .wrapper {
      border-radius: 10px;
      background: #FFF;
      padding: 12px;
    }
    h4 {
      margin: 12px 0 6px;
      text-transform: uppercase;
    }
  }
  :global(.ant-layout-header) {
    display: none !important;
  }
  :global(.ant-layout-content) {
    padding: 0 !important;
  }
}

.summary {
  background-color: #F5F6FA;
  .money_container {
    width: 100%;
    padding: 0 15px;
    gap: 5px;
    :global(.ant-space) {
      width: 100%;
      justify-content: space-between;
      gap: 0;
      :global(.ant-space-item) {
        span {
          font-size: 14px;
          font-weight: 600 !important;
          color: #868CAA;
        }
        b {
          font-size: 14px;
          color: #2C3782;
        }
      } 
    }
    .exemption {
      padding: 0;
      margin: 0;
      :global(.ant-input-number) {
        background-color: #fff;
      }
      input {
        width: 100px;
      }
      :global(.ant-input-number-group-addon) {
        background-color: #fff;
        font-weight: 600 !important;
      }
    }
  }
}