@import url(../../assets/style/mixins.less);

.form-container {
    padding: 17px 24px;
    max-width: 50vw;

    .avatar-upload {
        display: block;
        position: relative;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;

        .icon-add-img {
            position: absolute;
            bottom: 8px;
            right: 8px;
            transform: scale(2);
        }
    }

    .mul-select :global(.ant-select-selection-item) * {
        color: #fff;
      }
    .dropdown-mul-select {
        :global(.ant-select-item-option-content) {
          color: @navy1;
        }
        :global(.ant-select-item-option-selected) * {
          color: #fff;
        }
      }
}