@import "../../../../assets/style/mixins.less";

.container_filter {
  .input {
    border: 1px solid @grayLine !important;
  }
  .date_picker {
    border: 1px solid @grayLine !important;
  }
  .button {
    border: 1px solid @grayLine !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
.menu {
  padding: 10px !important;
  :global(.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child) {
    padding: 10px !important;
    width: 200px !important;
    span {
      color: @navy1 !important;
      font-weight: 700 !important;
    }
  }
}

.sider-filter {
  overflow: hidden;
  position: sticky;
  top: 106px;
  height: calc(100vh - 116px);
  background-color: #fff !important;
  border-top: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  z-index: 0;

  /* width */
  &::-webkit-scrollbar {
    width: 6px !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(186, 192, 198);
    border-radius: 10px;
  }

  .wrapper {
    padding: 15px;
    border-bottom: 1px dashed #e0e0e0;

    &:last-child {
      border: none;
    }
  }

  .wrapper-last {
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;

    button {
      flex: 1;
      padding-inline: 0px;
    }
  }

  .label {
    display: block;
    padding-bottom: 5px;
  }
  .radio-item {
    span {
      color: @navy1;
    }
  }
}
