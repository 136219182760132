.drawer {
  :global(.ant-drawer-footer) {
    padding: 16px 48px;
    background: #fff;
    button {
      min-width: 120px;
    }
  }

  :global(.ant-drawer-body) {
    background: #f5f7fb;
  }
}

.drawer-header {
  background: #fff;
  padding: 20px;

  h1 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }
}

.drawer-body {
  padding: 20px;
}

.service-form {
  padding: 8px 35px 8px 12px;
  border-radius: 15px;
  background: #fff;

  .trash {
    position: absolute;
    right: -19px;
    bottom: 13px;
    cursor: pointer;
  }
}

.form-item {
  :global(.ant-row.ant-form-item-row) {
    flex-direction: column;
  }

  :global(.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before) {
    display: none;
  }

  :global(.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after) {
    display: inline-block;
    margin-inline-start: 4px;
    color: #ff4d4f;
    font-size: 10px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
    visibility: unset !important;
  }

  .input-number {
    width: 100%;
  }
}

.add-service {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  color: #6576ff;
  padding: 10px;
  cursor: pointer;
  padding-bottom: 14px;
  width: fit-content;
}

.drawer-footer {
  padding: 16px 48px;
  background: #fff;
}

.table-packet-service {
  :global(.ant-table-cell) {
    // font-size: 14px!important;
    padding: 12px !important;
  }

  :global(.ant-table-body .ant-table-cell) {
    padding: 12px !important;
  }

  :global(.ant-table-summary .ant-table-cell) {
    padding: 12px !important;
  }

  :global(.ant-table-thead .ant-table-cell) {
    color: #6576ff;
  }
}

.text--bold {
  font-size: 14px;
  font-weight: 700 !important;
}

.text--blue {
  font-size: 14px;
  font-weight: 700 !important;
  color: #6576FF !important;
}

.text-14 {
  font-size: 14px !important;
}


.active-modal {
  padding: 24px 24px;
  width: 450px !important;
  border-radius: 10px;

  .title {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    line-height: normal;
  }

  .content {

    .content__row {
      margin: 24px;
      padding: 24px 12px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      background: #F6F8FE;
      border-radius: 10px;
      font-size: 14px;
      span, div {
        color: #2c3782;
      }
      :global(.ant-form-item-control-input) {
        min-height: 22px;
      }
    }

    .date-input {
      height: 30px;
      border-radius: 10px;
      background: #FFF;
      margin-bottom: 0;

      :global(.ant-form-item-control-input) {
        height: 30px !important;
        min-height: 30px !important;
      }

      :global(.ant-form-item-explain.ant-form-item-explain-connected) {
        display: none !important;
      }

      :global(.ant-input-affix-wrapper) {
        background: #fff;
        border-radius: 10px;
        height: 30px;
        display: flex;
        align-items: center;

        input {
          background: #fff;
        }
      }

      :global(.ant-input-suffix) {
        display: flex;
        align-items: center;

        &>div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .calendar {
        width: 20px;
        height: 20px;
        filter: grayscale(1)
      }
    }
  }

  :global(.ant-modal-close) {
    display: none;
  }

  .footer {
    margin: 20px 20px 0 !important;
    button {
      width: 100%;
      height: 36px;
    }
  }
}


.text-fade {
  opacity: 0.5;
}