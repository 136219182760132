.modal-wrap {

    :global(.ant-modal-content) {
      padding: 24px;
      width: 419px;
    }
  
    :global(.ant-modal-header) {
      border: none;
      padding: 0;
      div{
        color: #2C3782;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  
    :global(.ant-modal-body) {
      padding: 12px 0;
    }
  
    :global(.ant-modal-footer) {
      border: 0;
      padding: 0;
    }
  
    :global(.ant-modal-close-x) {
      display: none;
    }
  }

  .style-input {
    background-color: #f6f8fe;
    padding: 2px 0;
    border-radius: 10px;
  
    :global(.ant-input-group-addon) {
      background-color: #f6f8fe;
      border: none;
      padding-right: 0px;
    }
  
    input {
      padding-left: 6px;
      &:hover {
        border-color: #f6f8fe !important;
      }
      &:focus {
        border-color: #f6f8fe !important;
        box-shadow: none;
      }
    }
  }
  
  .header {
    background-color: #f6f8fe;
    padding: 7px 12px;
    display: flex;
    margin: 12px 20px 12px 0px;
    border-radius: 10px;
    gap: 160px;
    span {
      font-size: 14px;
      line-height: 22px;
      color: #6576ff;
    }
  }
  
  .input-all {
    width: 290px !important;
    background-color: #fff !important;
    border: none !important;
    pointer-events: none;
    padding-left: 4px;
  }
  
  .form {
    width: 390px;
    max-height: 342px;
    overflow-y: auto;
    padding-right: 20px;
    scrollbar-width: thin;
  }
  
  .form-item {
    display: flex;
    align-items: center;
    gap: 8px;
    // margin: 8px 0;
    :global(.ant-checkbox-wrapper) {
      margin: 0 !important;
    }
    // :global(.ant-checkbox) {
    //   top: 0px !important;
    // }
    :global(.ant-form-item) {
      &:nth-child(3) {
        width: 201px;
        input {
          height: 41px;
        }
      }
    }
  }
  
  .name-input {
    background: #ffffff !important;
    border: none !important;
    padding: 4px;
  }
  
  .discount-input {
    background-color: #f6f8fe;
    border-radius: 10px;
    max-width: 125px;
    float: none;
    :global(.ant-input-number-group-addon) {
      background-color: #e3e6ef;
      border: none;
      position: relative;
      right: 7px;
      z-index: 1;
      height: 31px;
      line-height: 31px;
      width: 31px;
      display: block;
      text-align: center;
      border-radius: 5px;
      border-start-start-radius: 5px !important;
      border-end-start-radius: 5px !important;
      text-align: center;
      margin: 5px 5px 5px 0;
    }
    :global(.ant-input-wrapper) {
      display: flex;
      align-items: center;
    }
    :global(.ant-input-number-handler-wrap) {
      display: none;
    }
    :global(.ant-input-number-focused) {
      box-shadow: none;
    }
  
    :global(.ant-input-number) {
      float: none;
      &:hover {
        border-color: #f6f8fe !important;
      }
      &:focus {
        border-color: #f6f8fe !important;
        box-shadow: none;
      }
    }
  }
  
  .hidden-icon-delete {
    display: none;
  }
  
  .show-icon-delete {
    margin-right: -22px;
    position: relative;
    left: -4px;
  }
  
  