@range-left: 14px;

.align-left (@margin : @range-left) {
    margin-left:@margin;
}

.flow-container-bottom(@mrTop : 16px,@bottom:0) {
    position: relative;
    margin-top: @mrTop;
    bottom: @bottom;
    left: 0;
    right: 0;
}